<template>
    <div class="conv-details--item" :class="{ compact: compact }">
      <h4 class="conv-details--item__label text-block-title">
        <span class="item__title">
          {{ title }}
        </span>
        <slot name="button" />
      </h4>
      <div v-if="value" class="conv-details--item__value">
        <slot>
          {{ initialTimeStamp }}
        </slot>
      </div>
    </div>
  </template>
  
  <script>
  import timeMixin from 'dashboard/mixins/time';
  
  export default {
    mixins: [timeMixin],
    props: {
      title: { type: String, required: true },
      icon: { type: String, default: '' },
      emoji: { type: String, default: '' },
      value: { type: [String, Number], default: '' },
      compact: { type: Boolean, default: false },
    },
    data() {
      return {
        initialTimeStamp: this.timeZoneLocal(this.value, this.$root.$i18n.locale)
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .conv-details--item {
    overflow: auto;
    padding: var(--space-slab) var(--space-normal);
  
    &.compact {
      padding: 0;
    }
  
    .conv-details--item__label {
      align-items: center;
      display: flex;
      justify-content: space-between;
  
      .edit-button {
        padding: 0;
      }
    }
  
    .conv-details--item__value {
      word-break: break-all;
    }
  }
  </style>
  