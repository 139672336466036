<template>
  <div class="inbox--name">
    <div class="inbox--icon">
      <fluent-icon
        v-if="computedInboxClass !== 'brand-conversa'"
        :icon="computedInboxClass"
        size="12"
      />
      <svg
        v-else
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.44206 3.42186L0.306083 5.27597C-0.102028 5.62976 -0.102028 6.21257 0.306083 6.56636L0.604092 6.82474L1.36159 7.48241L3.26415 9.13394L3.21718 9.00769C2.87953 8.09605 3.1379 7.06256 3.86898 6.36231L5.18286 5.11008C5.51463 4.79446 5.51463 4.27184 5.18286 3.93713L4.78649 3.53636C4.16112 2.90511 3.11295 2.84052 2.44353 3.42186H2.44206Z"
          fill="currentColor"
        />
        <path
          d="M10.3925 8.03146L11.8135 6.41957C12.0616 6.13918 12.0616 5.68849 11.8135 5.4081L11.6286 5.19817L11.1412 4.64473L9.79647 3.11798L9.83171 3.23249C10.0827 4.04724 9.89189 4.92512 9.32817 5.52114L8.23156 6.67354C7.93649 6.98329 7.93649 7.47948 8.23156 7.77309L8.58095 8.11954C9.09623 8.63188 9.90804 8.58197 10.394 8.03146H10.3925Z"
          fill="currentColor"
        />
        <path
          d="M4.34014 10.0676L6.17958 11.6634C6.49814 11.9393 7.00607 11.8748 7.31289 11.5254L7.5375 11.2699L8.11003 10.6196L9.55897 8.97541L9.43712 9.02826C8.66934 9.35709 7.79147 9.21029 7.1749 8.62895L7.16168 8.61721L5.9535 7.46775C5.6276 7.15799 5.0903 7.1712 4.75119 7.50738L4.33867 7.91696C3.70742 8.54381 3.70742 9.52004 4.33867 10.0676H4.34014Z"
          fill="currentColor"
        />
        <path
          d="M8.9423 2.15054L7.31866 0.309637C7.01185 -0.0397526 6.50391 -0.104346 6.18535 0.171643L5.94753 0.378634L5.31921 0.92474L3.57227 2.44121L3.72934 2.40304C4.67328 2.17109 5.67448 2.47057 6.32922 3.15614L6.34243 3.17082L7.53153 4.42011C7.83247 4.73573 8.31105 4.74894 8.60172 4.45534L8.9423 4.11035C9.4517 3.59654 9.4517 2.72894 8.9423 2.15201V2.15054Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <p class="text-truncate label">
      {{ inbox.name }}
    </p>
  </div>
</template>
<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    computedInboxClass() {
      const { phone_number: phoneNumber, channel_type: type } = this.inbox;
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
  },
};
</script>
<style scoped>
.inbox--name {
  max-width: calc(100% / 3);
  display: flex;
  padding: var(--space-micro) 0;
  line-height: var(--space-normal);
  font-weight: var(--font-weight-normal);
  background: none;
  color: var(--conversa2-blue-600-color);
  font-size: var(--font-size-mini);
  height: 16px;
  align-items: center;
  gap: 4px;
}

.inbox--icon {
  margin-top: var(--space-smaller);
}

.label {
  line-height: var(--space-normal);
  font-weight: var(--font-weight-normal);
  background: none;
  font-size: var(--font-size-mini);
  padding: 0;
  margin: 0;
  color: var(--conversa2-blue-600-color);
}
</style>
