<template>
  <div class="sidebar-toggle__wrap">
    <woot-button
      variant="smooth"
      size="tiny"
      color-scheme="secondary"
      :class="{
        'sidebar-toggle--button': true,
        'no-margin': !currentChat.id,
      }"
      icon="arrow-chevron-right"
      @click="toggleConversationLayout"
    />
  </div>
</template>

<script>
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { mapGetters } from 'vuex';

export default {
  mixins: [uiSettingsMixin],
  props: {
    backUrl: {
      type: [String, Object],
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
  },
  methods: {
    /**
     * Alterna la visibilidad de la lista de chats en la interfaz de conversación.
     *
     * Este método obtiene el estado actual de la configuración `show_chatlist` desde `uiSettings`.
     * Si `show_chatlist` es actualmente verdadero, se cambiará a falso, y viceversa.
     * El estado actualizado se guarda nuevamente en la configuración de la interfaz de usuario.
     *
     * @method toggleConversationLayout
     * @returns {void} No devuelve ningún valor.
     */
    toggleConversationLayout() {
      const { show_chatlist: showChatList = true } = this.uiSettings;
      const newShowChatList = !showChatList;
      this.updateUISettings({
        show_chatlist: newShowChatList,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.sidebar-toggle__wrap {
  .sidebar-toggle--button {
    margin: 0px 16px 0px -16px;
    z-index: var(--z-index-very-high);
    background: var(--white);
    padding: inherit 0;
    border-top-right-radius: calc(var(--space-medium) + 1px);
    border-bottom-right-radius: calc(var(--space-medium) + 1px);
    border: 1px solid var(--color-border-light);
    border-right: 0;
    box-sizing: border-box;
  }

  .no-margin {
    margin: 16px 0px 0px 0px;
  }
}
</style>
