<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.NAME.LABEL') }}
            <span class="required">*</span>
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <div v-if="isEnabledIntertelUCChannel" class="medium-12 columns">
          <!-- <div class="medium-6 columns">
            <label :class="{ error: $v.agentPhone.$error }">
              <div class="label-container">
                <span class="label-text">
                  {{ $t('AGENT_MGMT.EDIT.FORM.PHONE.LABEL') }}
                </span>
                <span
                  v-tooltip.right-start="$t('AGENT_MGMT.EDIT.FORM.PHONE.HELP')"
                  class="label-text-icon"
                >
                  <fluent-icon
                    icon="info"
                    type="solid"
                    width="30"
                    height="28"
                    viewbox="0 0 30 28"
                  />
                </span>
              </div>
              <div class="custom-input">
                <input
                  v-model.trim="agentPhone"
                  type="text"
                  :placeholder="$t('AGENT_MGMT.EDIT.FORM.PHONE.PLACEHOLDER')"
                  class="form-control"
                  @input="handlePhoneInput()"
                />
              </div>
              <span v-if="$v.agentPhone.$error" class="message">
                {{ $t('AGENT_MGMT.EDIT.FORM.PHONE.ERROR') }}
              </span>
            </label>
          </div> -->

          <div class="row">
            <div class="medium-6 columns">
              <label :class="{ error: $v.agentExtension.$error }">
                <div class="label-container">
                  <span class="label-text">
                    {{ $t('AGENT_MGMT.EDIT.FORM.EXTENSION.LABEL') }}
                  </span>
                  <span
                    v-tooltip.right-start="
                      $t('AGENT_MGMT.EDIT.FORM.EXTENSION.HELP')
                    "
                    class="label-text-icon"
                  >
                    <fluent-icon
                      icon="info"
                      type="solid"
                      width="30"
                      height="28"
                      viewbox="0 0 30 28"
                    />
                  </span>
                </div>
                <div class="">
                  <input
                    v-model.trim="agentExtension"
                    type="text"
                    :placeholder="
                      $t('AGENT_MGMT.EDIT.FORM.EXTENSION.PLACEHOLDER')
                    "
                    class="form-control"
                    @input="handleExtensionInput"
                  />
                </div>
                <span
                  v-if="$v.agentExtension.$error && $v.agentExtension.maxLength"
                  class="message"
                >
                  {{ $t('AGENT_MGMT.EDIT.FORM.EXTENSION.ERROR') }}
                </span>
                <span v-else-if="!$v.agentExtension.maxLength" class="message">
                  {{ $t('AGENT_MGMT.EDIT.FORM.EXTENSION.TOO_LONG') }}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="medium-12 modal-footer">
          <div class="medium-6 columns">
            <woot-submit-button
              :disabled="
                $v.agentType.$invalid ||
                  $v.agentName.$invalid ||
                  ($v.agentPhone.$invalid && isEnabledIntertelUCChannel) ||
                  ($v.agentExtension.$invalid && isEnabledIntertelUCChannel) ||
                  uiFlags.isUpdating
              "
              :button-text="$t('AGENT_MGMT.EDIT.FORM.SUBMIT')"
              :loading="uiFlags.isUpdating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
          <div class="medium-6 columns text-right">
            <woot-button
              icon="lock-closed"
              variant="clear"
              @click.prevent="resetPassword"
            >
              {{ $t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_BUTTON') }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import Auth from '../../../../api/auth';
import {
  isPhoneE164OrEmptyWithOutPlus,
  isExtensionValid,
} from 'shared/helpers/Validators';
import FluentIcon from 'shared/components/FluentIcon/Index.vue';

export default {
  components: {
    WootSubmitButton,
    Modal,
    FluentIcon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    extension: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      required: true,
    },
    enabledFeatures: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      agentName: this.name,
      agentType: this.type,
      agentCredentials: {
        email: this.email,
      },
      agentPhone: this.phone,
      agentExtension: this.extension,
      show: true,
    };
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentType: {
      required,
    },
    agentPhone: {
      // isPhoneE164OrEmptyWithOutPlus,
    },
    agentExtension: {
      maxLength: maxLength(20),
      isExtensionValid,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('AGENT_MGMT.EDIT.TITLE')} - ${this.name}`;
    },
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    /**
     * Retorna si esta habilitado el feature flag de canal de Intertel UC para el cliente
     */
    isEnabledIntertelUCChannel() {
      if (Object.keys(this.enabledFeatures).length === 0) {
        return false;
      }

      return this.enabledFeatures.channel_intertel_uc;
    },
  },
  methods: {
    // Filtrar la entrada para bloquear la letra "ñ" (insensible a mayúsculas/minúsculas)
    handleExtensionInput() {
      this.agentExtension = this.agentExtension.replace(/ñ/gi, '');
      this.$v.agentExtension.$touch();
    },
    /**
     * Maneja la entrada de números de teléfono del agente.
     *
     * @function
     * @name handlePhoneInput
     * @returns {void}
     */
    handlePhoneInput() {
      // Elimina caracteres no numéricos del número de teléfono del agente.
      this.agentPhone = this.agentPhone.replace(/[^0-9]/g, '');

      // Reemplaza todas las ocurrencias de acento grave ('`') o acento agudo ('´') con una cadena vacía.
      this.agentPhone = this.agentPhone.replace(/[`´]/g, '');

      // Marca el campo de entrada del número de teléfono como "tocado" para activar las validaciones.
      this.$v.agentPhone.$touch();
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async editAgent() {
      try {
        await this.$store.dispatch('agents/update', {
          id: this.id,
          name: this.agentName,
          role: this.agentType,
          phone_number: this.agentPhone,
          phone_extension: this.agentExtension,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const {
          response: { data: { error: errorResponse = '' } = {} } = {},
        } = error;
        let errorMessage = '';
        if (error.response.status === 422) {
          errorMessage = this.$t('AGENT_MGMT.EDIT.API.EXIST_MESSAGE');
        } else {
          errorMessage = this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE');
        }
        this.showAlert(errorResponse || errorMessage);
      }
    },
    async resetPassword() {
      try {
        await Auth.resetPassword(this.agentCredentials);
        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
<style scoped>
.label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
}

.label-text {
  display: flex;
  align-items: center;
}

.label-text-icon {
  height: 28px;
}

.custom-input {
  position: relative;
}

.custom-input input {
  padding-left: 20px;
}

.custom-input::before {
  content: '+';
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-input input::placeholder {
  padding-left: 4px;
}

.custom-input input {
  text-indent: 8px;
}

.required {
  margin-left: 4px;
  color: #de1e27;
}
</style>
