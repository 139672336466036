var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"mouseenter":function($event){_vm.isSettingPhoneAndExtension || !_vm.isCurrentUserExtensionInUse
      ? _vm.changeIcon('solid')
      : _vm.changeIcon('outline')},"mouseleave":function($event){_vm.isDropdownVisible ? _vm.changeIcon('solid') : _vm.changeIcon('outline')}}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!_vm.isDisabledCall ? _vm.$t('CONTACT_PANEL.CALL') : ''),expression:"!isDisabledCall ? $t('CONTACT_PANEL.CALL') : ''"}],class:[
      'button',
      ("call-button-" + _vm.callButtonSize),
      _vm.colorScheme,
      _vm.hasOnlyIconClasses,
      _vm.variantClasses,
      _vm.classNames,
      _vm.isDisabled ? 'disabled' : '',
      _vm.isExpanded ? 'expanded' : '' ],attrs:{"type":_vm.type,"disabled":_vm.isDisabled},on:{"click":_vm.toggleDropdown}},[(_vm.isLoading)?_c('spinner',{attrs:{"size":"small"}}):_vm._e(),_vm._v(" "),_c('fluent-icon',{class:_vm.showAlternateIcon ? 'solid-icon' : 'outline-icon',attrs:{"type":_vm.showAlternateIcon ? 'solid' : 'outline',"icon":"call-connecting","size":"16px","viewbox":"0 0 16 16"}}),_vm._v(" "),(_vm.isSettingPhoneAndExtension)?_c('fluent-icon',{staticClass:"chevron-icon-class",attrs:{"icon":"call-connecting-chevron","size":"12px","viewbox":"0 0 12 12"}}):_vm._e(),_vm._v(" "),(_vm.$slots.default)?_c('span',{staticClass:"button__content"},[_vm._t("default")],2):_vm._e()],1),_vm._v(" "),(_vm.isDropdownVisible && _vm.isSettingPhoneAndExtension)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.onClickaway),expression:"onClickaway"}],staticClass:"dropdown",on:{"click":_vm.handleDropdownClick}},_vm._l((_vm.dropdownOptions),function(option,index){return _c('div',{key:index,class:['option', _vm.isOptionDisabled(index) ? 'disabled-option' : ''],on:{"click":function($event){return _vm.selectOption(index)},"mouseenter":function($event){return _vm.changeDropdownIcon(index, 'solid')},"mouseleave":function($event){return _vm.changeDropdownIcon(index, 'outline')}}},[_c('fluent-icon',{class:_vm.showDropdownIcon[index]
            ? 'solid-option-icon'
            : 'outline-option-icon',attrs:{"type":_vm.showDropdownIcon[index] ? 'solid' : 'outline',"icon":option.icon,"size":"16px","viewbox":"0 0 16 16"}}),_vm._v(" "),_c('span',{staticClass:"option-text"},[_vm._v(_vm._s(option.text))])],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }