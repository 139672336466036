/**
 * Devuelve una matriz de objetos que contienen la clave, la etiqueta y el tipo de entrada para cada tipo de acción de macro
 * @param [i18n=null] - El objeto i18n de la biblioteca vue-i18.
 */
export const MACRO_ACTION_TYPES = (i18n = null) => [
  {
    key: 'assign_team',
    label: i18n.t('MACROS.ACTIONS_TYPES.ASSIGN_A_TEAM'),
    inputType: 'search_select',
  },
  {
    key: 'assign_agent',
    label: i18n.t('MACROS.ACTIONS_TYPES.ASSIGN_AN_AGENT'),
    inputType: 'search_select',
  },
  {
    key: 'add_label',
    label: i18n.t('MACROS.ACTIONS_TYPES.ADD_A_LABEL'),
    inputType: 'multi_select',
  },
  {
    key: 'remove_label',
    label: i18n.t('MACROS.ACTIONS_TYPES.REMOVE_A_LABEL'),
    inputType: 'multi_select',
  },
  {
    key: 'remove_assigned_team',
    label: i18n.t('MACROS.ACTIONS_TYPES.REMOVE_ASSIGNED_TEAM'),
    inputType: null,
  },
  {
    key: 'send_email_transcript',
    label: i18n.t('MACROS.ACTIONS_TYPES.SEND_EMAIL_TRANSCRIPT'),
    inputType: 'email',
  },
  {
    key: 'mute_conversation',
    label: i18n.t('MACROS.ACTIONS_TYPES.MUTE_CONVERSATION'),
    inputType: null,
  },
  {
    key: 'snooze_conversation',
    label: i18n.t('MACROS.ACTIONS_TYPES.SNOOZE CONVERSATION'),
    inputType: null,
  },
  {
    key: 'resolve_conversation',
    label: i18n.t('MACROS.ACTIONS_TYPES.RESOLVE CONVERSATION'),
    inputType: null,
  },
  {
    key: 'send_attachment',
    label: i18n.t('MACROS.ACTIONS_TYPES.SEND_ATTACHMENT'),
    inputType: 'attachment',
  },
  {
    key: 'send_message',
    label: i18n.t('MACROS.ACTIONS_TYPES.SEND_A_MESSAGE'),
    inputType: 'textarea',
  },
  {
    key: 'add_private_note',
    label: i18n.t('MACROS.ACTIONS_TYPES.ADD_A_PRIVATE_NOTE'),
    inputType: 'textarea',
  },
];
