<template>
  <div>
    <div class="separator">
      O
    </div>
    <a :href="getFacebookAuthUrl()" class="container_button">
      <button class="button expanded button__facebook_login">
        <img
          src="/assets/images/auth/facebook.svg"
          alt="Facebook Logo"
          class="icon"
        />
        <slot>{{ $t('LOGIN.OAUTH.FACEBOOK_LOGIN') }}</slot>
      </button>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * Retorna la URL para login con Facebook
     */
    getFacebookAuthUrl() {
      const baseUrl = `https://www.facebook.com/${window.chatwootConfig.fbApiVersion}/dialog/oauth`;
      const clientId = window.chatwootConfig.fbAppId;
      const redirectUri = `${window.chatwootConfig.hostURL}/omniauth/facebook/callback`;
      const responseType = 'code';
      const scope = 'public_profile,email';

      const queryString = new URLSearchParams({
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
        scope: scope,
      }).toString();

      return `${baseUrl}?${queryString}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin: var(--space-medium) var(--space-zero);
  margin-bottom: var(--space-normal);
  gap: var(--space-slab);
  color: var(--conversa2-neutral-800-color);
  font-size: var(--font-size-default);
  line-height: var(--space-medium);
  word-wrap: break-word;
  &::before,
  &::after {
    content: '';
    flex: 1;
    height: 1px;
    background: var(--conversa2-neutral-200-color);
  }
}
.container_button {
  background-color: transparent;
  color: transparent;
}
.button__facebook_login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-slab);
  gap: var(--space-one);
  border-radius: 4px;
  background: #0866ff !important;
  font-size: var(--font-size-default);
  line-height: var(--space-medium);
}
.button__facebook_login:hover {
  background: #0153d9 !important;
}
</style>
