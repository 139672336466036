export const MESSAGE_STATUS = {
  FAILED: 'failed',
  SENT: 'sent',
  DELIVERED: 'delivered',
  READ: 'read',
  PROGRESS: 'progress',
};

export const MESSAGE_TYPE = {
  INCOMING: 0,
  OUTGOING: 1,
  ACTIVITY: 2,
  TEMPLATE: 3,
};

export const CONVERSATION_STATUS = {
  OPEN: 'open',
  RESOLVED: 'resolved',
  PENDING: 'pending',
  SNOOZED: 'snoozed',
};
// Size in mega bytes
export const MAXIMUM_FILE_UPLOAD_SIZE = 40;
export const MAXIMUM_FILE_UPLOAD_SIZE_TWILIO_SMS_CHANNEL = 5;

/* `constpressedTypesWhatsappCloud` es un objeto donde se mapean diferentes tipos de documentos MIME y extenciones a su máximo permitido en megabytes (MB) tanto en Whatsapp Cloud como por Conversa2. Si el tipo de MIME o extencion no es admitido por Whatasapp Cloud pero si por la plataforma Conversa 2, esta le aplicara un máximo teninedo en cuenta que se desea enviar por Whatsapp Cloud pero realmente el fichero se subira a la nube y se enviará una liga para acceder al mismo. Por ejemplo, el tipo MIME `application/zip` tiene un tamaño de archivo máximo permitido de 100 MB si se envia por Whatsapp Cloud. Esta funcion se invoca en la función `getSizeFileWhatsappCloud` para determinar el tamaño de archivo máximo permitido para un archivo comprimido dado Tipo de archivo. */
const compressedTypesWhatsappCloud = {
  'application/zip': 100,
  'application/x-zip-compressed': 100,
  'application/x-rar-compressed': 100,
  'application/vnd.rar': 100,
  rar: 100,
  'application/x-7z-compressed': 100,
  '7z': 100,
  'application/x-compressed': 100,
  'application/x-tar': 100,
};

/* `compressedTypesWhatsappTwilio` es un objeto donde se mapean diferentes tipos de documentos MIME y extenciones a su máximo permitido en megabytes (MB) tanto en Whatsapp Twilio como por Conversa2. Si el tipo de MIME o extencion no es admitido por Whatasapp Twilio pero si por la plataforma Conversa 2, esta le aplicara un máximo teninedo en cuenta que se desea enviar por Whatsapp Twilio pero realmente el fichero se subira a la nube y se enviará una liga para acceder al mismo. Por ejemplo, el tipo MIME `application/zip` tiene un tamaño de archivo máximo permitido de 16 MB si se envia por Whatsapp Twilio. Esta funcion se invoca en la función `getSizeFileWhatsappTwilio` para determinar el tamaño de archivo máximo permitido para un archivo comprimido dado Tipo de archivo. */
const compressedTypesWhatsappTwilio = {
  'application/zip': 16,
  'application/x-zip-compressed': 16,
  'application/x-rar-compressed': 16,
  'application/vnd.rar': 16,
  rar: 16,
  'application/x-7z-compressed': 16,
  '7z': 16,
  'application/x-compressed': 16,
  'application/x-tar': 16,
};

/* `const documentsTypesWhatsappCloud` es un objeto donde se mapean diferentes tipos de documentos MIME y extenciones a su máximo permitido en megabytes (MB) tanto en Whatsapp Cloud como por Conversa2.Si el tipo de MIME no es admitido por Whatasapp Cloud pero si por la plataforma Conversa 2, esta le aplicara un máximo teninedo en cuenta que se desea enviar por Whatsapp Cloud pero realmente el fichero se subira a la nube y se enviará una liga para acceder al mismo. Por ejemplo, el tipo MIME `aplicación/pdf` tiene un tamaño de archivo máximo permitido de 100 MB para Whatsapp Cloud. Este objeto se usa en la función `getSizeFileWhatsappCloud`para determinar el tamaño de archivo máximo permitido para un tipo de archivo de documento determinado. */
const documentsTypesWhatsappCloud = {
  ogx: 16,
  odp: 100,
  ods: 100,
  odt: 100,
  'application/ogg': 16,
  'application/msword': 100,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 100,
  'application/vnd.ms-excel': 100,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 100,
  'application/vnd.ms-powerpoint': 100,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 100,
  'application/vnd.oasis.opendocument.text': 100,
  'text/csv': 100,
  'text/plain': 100,
  'application/json': 100,
  'application/pdf': 100,
  'text/rtf': 100,
  'application/vnd.oasis.opendocument.spreadsheet': 100,
  'application/vnd.oasis.opendocument.presentation': 100,
};
/* `const documentsTypesWhatsappTwilio` es un objeto donde se mapean diferentes tipos de documentos MIME y extenciones a su máximo permitido en megabytes (MB) tanto en Whatsapp Twilio como por Conversa2.Si el tipo de MIME no es admitido por Whatasapp Twilio pero si por la plataforma Conversa 2, esta le aplicara un máximo teninedo en cuenta que se desea enviar por Whatsapp Twilio pero realmente el fichero se subira a la nube y se enviará una liga para acceder al mismo. Por ejemplo, el tipo MIME `aplicación/pdf` tiene un tamaño de archivo máximo permitido de 16 MB para Whatsapp Twilio. Este objeto se usa en la función `getSizeFileWhatsappTwilio`para determinar el tamaño de archivo máximo permitido para un tipo de archivo de documento determinado. */
const documentsTypesWhatsappTwilio = {
  ogx: 16,
  odp: 16,
  ods: 16,
  odt: 16,
  'application/ogg': 16,
  'application/msword': 16,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 16,
  'application/vnd.ms-excel': 16,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 16,
  'application/vnd.ms-powerpoint': 16,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 16,
  'application/vnd.oasis.opendocument.text': 16,
  'text/csv': 16,
  'text/plain': 16,
  'application/json': 16,
  'application/pdf': 16,
  'text/rtf': 16,
  'application/vnd.oasis.opendocument.spreadsheet': 16,
  'application/vnd.oasis.opendocument.presentation': 16,
};
/* `const imagesTypesWhatsappCloud` es un objeto que asigna diferentes tipos MIME y de extenciones de imágenes a su archivo máximo permitido tamaño en megabytes (MB) para cargas de archivos  en Whatsapp Cloud o en la plataforma de Conversa2 siempre que se utilice como canal El Whatsapp Cloud. Si el tipo de MIME o extencion no es admitido por Whatasapp Cloud pero si por la plataforma Conversa 2, esta le aplicara un máximo teninedo en cuenta que se desea enviar por Whatsapp Cloud pero realmente el fichero se subira a la nube y se enviará una liga para acceder al mismo.Por ejemplo, el tipo MIME `image/png` tiene un máximo tamaño de archivo permitido de 5 MB. Este objeto se usa en la función `getSizeFileWhatsappCloud` para determinar el tamaño de archivo máximo permitido para un tipo de archivo de imagen determinado. */
const imagesTypesWhatsappCloud = {
  'image/png': 5,
  'image/jpeg': 5,
  'image/tiff': 5,
  'image/webp': 5,
  'image/svg+xml': 5,
};

/* `const imagesTypesWhatsappTwilio` es un objeto que asigna diferentes tipos MIME y extenciones de imágenes a su archivo máximo permitido tamaño en megabytes (MB) para cargas de archivos en Whatsapp Twilio o en la plataforma de Conversa2 siempre que se utilice como canal El Whatsapp Twilio. Si el tipo de MIME o extencion no es admitido por Whatasapp Twilio pero si por la plataforma Conversa 2, esta le aplicara un máximo teninedo en cuenta que se desea enviar por Whatsapp Twilio pero realmente el fichero se subira a la nube y se enviará una liga para acceder al mismo. Por ejemplo, el tipo MIME `image/png` tiene un máximo tamaño de archivo permitido de 16 MB. Este objeto se usa en la función `getSizeFileWhatsappTwilio` para determinar el tamaño de archivo máximo permitido para un tipo de archivo de imagen determinado. */
const imagesTypesWhatsappTwilio = {
  'image/png': 16,
  'image/jpeg': 16,
  'image/tiff': 16,
  'image/webp': 16,
  'image/svg+xml': 16,
};
/* `const audiosTypesWhatsapp` es un objeto que asigna diferentes tipos MIME de audio a su archivo máximo permitido tamaño en megabytes (MB) para cargas de archivos en WhatsApp. Por ejemplo, el tipo MIME `audio/wav` tiene un tamaño de archivo máximo permitido de 16 MB. Este objeto se usa en la función `getSizeFileWhatsappCloud` y `getSizeFileWhatsappTwilio` para determinar el tamaño de archivo máximo permitido para un tipo de archivo de audio dado. */
const audiosTypesWhatsapp = {
  'audio/wav': 16,
  'audio/x-wav': 16,
  'audio/mpeg': 16,
  'audio/x-m4a': 16,
  'audio/vnd.dlna.adts': 16,
  'audio/aac': 16,
  'audio/ogg': 16,
  'audio/ogx': 16,
};

/* `const videosTypesWhatsapp` es un objeto que asigna diferentes tipos de MIME y extenciones de video a su archivo máximo permitido tamaño en megabytes (MB) para cargas de archivos. Por ejemplo, el tipo MIME `video/mp4` tiene un máximo tamaño de archivo permitido de 16 MB. Este objeto se usa en la función `getSizeFileWhatsappCloud` y `getSizeFileWhatsappTwilio` para determinar el tamaño de archivo máximo permitido para un tipo de archivo de video dado. */
const videosTypesWhatsapp = {
  'video/mp4': 16,
  'video/webm': 16,
  'video/avi': 16,
  'video/mov': 16,
  'video/wmv': 16,
  'video/ogx': 16,
  'video/x-ms-wmv': 16,
  'video/quicktime': 16,
};
/**
 * La función devuelve el tamaño de un archivo en WhatsApp en función de su tipo.
 * @param type - El parámetro de tipo es una cadena que representa el tipo MIME de un archivo.
 * @returns La función `getSizeFileWhatsapp` devuelve el tamaño (en MB) de un archivo de un tipo dado que
 *Se puede enviar a través de WhatsApp. El tamaño se determina en función del tipo de archivo, que se pasa
 * como argumento de la función. Si el tipo no se encuentra en el objeto `typeFile`, la función
 * devuelve `indefinido`.
 */
function getSizeFileWhatsappCloud(type) {
  const typeFile = {
    ...audiosTypesWhatsapp,
    ...documentsTypesWhatsappCloud,
    ...imagesTypesWhatsappCloud,
    ...videosTypesWhatsapp,
    ...compressedTypesWhatsappCloud,
  };
  return typeFile[type] || 16;
}

function getSizeFileWhatsappTwilio(type) {
  const typeFile = {
    ...audiosTypesWhatsapp,
    ...documentsTypesWhatsappTwilio,
    ...imagesTypesWhatsappTwilio,
    ...videosTypesWhatsapp,
    ...compressedTypesWhatsappTwilio,
  };
  return typeFile[type] || 16;
}

/**
 * La función comprueba si un tipo de archivo está vacío y asigna un tipo predeterminado si es necesario.
 * @param nameFile - El nombre del archivo, incluida su extensión.
 * @param typeFile - El tipo de archivo, como "imagen/jpeg" o "aplicación/pdf". es un opcional
 * parámetro que se puede pasar a la función.
 * @returns el `tipo` del archivo. Si se proporciona `typeFile`, devuelve `typeFile`. Si `typeFile` es
 * no proporcionado, comprueba la extensión del `nameFile` y devuelve el tipo MIME correspondiente.
 */
export function isEmptyTypeFile(nameFile, typeFile) {
  let type = typeFile;
  if (!typeFile) {
    const extension = nameFile.split('.').pop();
    type = extension;
    if (extension === 'aac') {
      type = `audio/${extension}`;
    }
  }
  return type;
}

/**
 * Esta función devuelve el tamaño máximo de carga de archivos para un tipo de canal determinado, con un valor predeterminado si no se reconoce el tipo de canal.
 * @param channelType - Tipo de canal
 * @returns el tamaño máximo de carga de archivos para un tipo de canal determinado. Si el tipo de canal es
 * 'Channel::FacebookPage', devuelve 25. En caso contrario, devuelve el valor de la constante
 * MAXIMUM_FILE_UPLOAD_SIZE.
 */
export function getSizeFile(channelType, file) {
  const { name, type } = file;
  const typeFile = file.type ? file.type : isEmptyTypeFile(name, type);
  console.info('.:: Data ::.', channelType, file, { type, typeFile });
  const channel = {
    'Channel::FacebookPage': 25,
    'Channel::Whatsapp': getSizeFileWhatsappCloud(typeFile),
    'Channel::TwilioSms': getSizeFileWhatsappTwilio(typeFile),
  };
  return channel[channelType] || MAXIMUM_FILE_UPLOAD_SIZE;
}

export const ALLOWED_FILE_TYPES =
  'image/*,' +
  'audio/*,' +
  'video/*,' +
  '.3gpp,' +
  'text/csv, text/plain, application/json, application/pdf, text/rtf,' +
  'application/zip, application/x-7z-compressed application/vnd.rar application/x-tar,' +
  'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text,' +
  'application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document,';

export const ALLOWED_FILE_TYPES_FOR_TWILIO_WHATSAPP =
  'image/png, image/jpeg,' +
  'audio/mpeg, audio/opus, audio/ogg, audio/amr,' +
  'video/mp4,' +
  'application/pdf,';

export const CSAT_RATINGS = [
  {
    key: 'disappointed',
    emoji: '😞',
    value: 1,
    color: '#FDAD2A',
  },
  {
    key: 'expressionless',
    emoji: '😑',
    value: 2,
    color: '#FFC532',
  },
  {
    key: 'neutral',
    emoji: '😐',
    value: 3,
    color: '#FCEC56',
  },
  {
    key: 'grinning',
    emoji: '😀',
    value: 4,
    color: '#6FD86F',
  },
  {
    key: 'smiling',
    emoji: '😍',
    value: 5,
    color: '#44CE4B',
  },
];

export const AUDIO_FORMATS = {
  WEBM: 'audio/webm',
  OGG: 'audio/ogg',
};

export const MESSAGE_VARIABLES = [
  {
    label: 'Conversation Id',
    key: 'conversation.id',
  },
  {
    label: 'Contact Id',
    key: 'contact.id',
  },
  {
    label: 'Contact name',
    key: 'contact.name',
  },
  {
    label: 'Contact first name',
    key: 'contact.first_name',
  },
  {
    label: 'Contact last name',
    key: 'contact.last_name',
  },
  {
    label: 'Contact email',
    key: 'contact.email',
  },
  {
    label: 'Contact phone',
    key: 'contact.phone',
  },
  {
    label: 'Agent name',
    key: 'agent.name',
  },
  {
    label: 'Agent first name',
    key: 'agent.first_name',
  },
  {
    label: 'Agent last name',
    key: 'agent.last_name',
  },
  {
    label: 'Agent email',
    key: 'agent.email',
  },
];

/**
 * La función extrae la extensión del archivo de una URL dada.
 * @param {string} url - El parámetro `url` es una cadena que representa una URL.
 * @returns La extesion
 */
export function getUrlExtension(url) {
  const pathname = new URL(url).pathname;
  return pathname.substring(pathname.lastIndexOf('.') + 1).trim();
}

/**
 * constante para definir los tipos de canales
 */
export const CHANNEL_TYPE = Object.freeze({
  FACEBOOK: 'Channel::FacebookPage',
  WHATSAPP: 'Channel::Whatsapp',
  WEB: 'Channel::WebWidget',
  TWITTER: 'Channel::TwitterProfile',
  TWILIO: 'Channel::TwilioSms',
  API: 'Channel::Api',
  EMAIL: 'Channel::Email',
  TELEGRAM: 'Channel::Telegram',
  LINE: 'Channel::Line',
  SMS: 'Channel::Sms',
  INTERTEL_UC: 'Channel::IntertelUc',
});
/**
 * constante para definir los nombres de los canales
 */
export const CHANNEL = Object.freeze({
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  WHATSAPP: 'Whatsapp',
});

/**
 * Iconos para diferentes tipos de archivos adjuntos.
 */
export const ATTACHMENT_ICONS = {
  image: 'image',
  audio: 'headphones-sound-wave',
  video: 'video',
  file: 'document',
  location: 'location',
  fallback: 'link',
};
