export const APP_BASE_URL = '';

export const MESSAGE_STATUS = {
  FAILED: 'failed',
  SUCCESS: 'success',
  PROGRESS: 'progress',
};

export const MESSAGE_TYPE = {
  INCOMING: 0,
  OUTGOING: 1,
  ACTIVITY: 2,
  TEMPLATE: 3,
};

/**
 * Tipos de contenido de mensaje disponibles.
 * @enum {string}
 */
export const MESSAGE_CONTENT_TYPE = {
  MISSED_CALL: 'missed_call',
  ANSWERED_CALL: 'call',
  STARTING_CALL: 'starting_call',
  ERROR_CALL: 'error_call',
};

/**
 * Iconos disponibles para diferentes tipos de llamadas.
 */
export const CALL_ICONS = {
  MISSED_CALL_INCOMING: 'call-arrow-incoming',
  MISSED_CALL_OUTGOING: 'call-arrow-outgoing',
  ANSWERED_CALL_INCOMING: 'call-arrow-incoming',
  ANSWERED_CALL_OUTGOING: 'call-arrow-outgoing',
  STARTING_CALL: 'call-arrow-outgoing',
  ERROR_CALL: 'call-arrow-outgoing',
  INCOMING: 'call-incoming',
  OUTGOING: 'call-outgoing',
  STARTING: 'call-starting',
  ERROR: 'call-error',
};

/**
 * Colores para estados de llamada en una elipse.
 * @enum {string}
 */
export const ELLIPSE_COLORS = {
  MISSED: '#FFE2D9', // Color para llamadas perdidas.
  ANSWERED_INCOMING: '#F3FAFE', // Color para llamadas contestadas entrantes.
  ANSWERED_OUTGOING: '#F3FAFE', // Color para llamadas contestadas salientes.
  STARTING_CALL: '#135899', // Color para llamadas en proceso de inicio.
  ERROR_CALL: '#FFE198', // Color para error en llamadas.
};

/**
 * Clases de iconos disponibles para diferentes tipos de llamadas.
 */
export const CALL_ICON_CLASS = {
  MISSED_CALL_ICON: 'missed-call-icon',
  CALL_ICON: 'call-icon',
  ANSWERED_CALL_ICON: 'answered-call-icon',
  STARTING_CALL_ICON: 'starting-call-icon',
  ERROR_CALL_ICON: 'error-call-icon',
};

/**
 * Tipos de llamadas disponibles.
 * @type {string[]}
 */
export const CALL_TYPES = [
  'missed_call',
  'call',
  'starting_call',
  'error_call',
];

/**
 * Tipos de Marcación.
 * @type {string[]}
 */
export const DIALING_TYPES = {
  PHONE: 0,
  EXTENSION: 1,
};

export const WOOT_PREFIX = 'chatwoot-widget:';
