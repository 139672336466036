const CALL_TYPES = ['missed_call', 'call', 'starting_call', 'error_call'];

const getLastNonActivityMessage = (messageInStore, messageFromAPI) => {
  // If both API value and store value for last non activity message
  // are available, then return the latest one.
  if (messageInStore && messageFromAPI) {
    if (messageInStore.created_at >= messageFromAPI.created_at) {
      return messageInStore;
    }
    return messageFromAPI;
  }

  // Otherwise, return whichever is available
  return messageInStore || messageFromAPI;
};

export default {
  methods: {
    lastMessage(m) {
      let lastMessageIncludingActivity = m.messages.last();

      const nonActivityMessages = m.messages.filter(
        message => message.message_type !== 2
      );
      let lastNonActivityMessageInStore = nonActivityMessages.last();
      let lastNonActivityMessageFromAPI = m.last_non_activity_message;

      // If API value and store value for last non activity message
      // is empty, then return the last activity message
      if (!lastNonActivityMessageInStore && !lastNonActivityMessageFromAPI) {
        return lastMessageIncludingActivity;
      }

      return getLastNonActivityMessage(
        lastNonActivityMessageInStore,
        lastNonActivityMessageFromAPI
      );
    },
    // segmenta cada mensaje con adjuntos en varios nuevos mensajes, dejando el texto solamente en el ultimo nuevo mensaje si el mensaje original tiene texto
    splitMessages(messages) {
      let newMessages = [];
      messages.forEach(message => {
        if (message?.attachments?.length > 1) {
          const length = message.attachments.length;
          message.attachments.forEach((attach, index) => {
            const newMessage = {
              ...message,
              id: `${message.id}-${index}`,
              content: index === length - 1 ? message.content : null,
              attachments: [attach],
            };
            newMessages = [...newMessages, newMessage];
          });
        } else {
          newMessages = [...newMessages, message];
        }
      });

      return newMessages;
    },
    readMessages(m) {
      const read = m.messages.filter(
        chat => chat.created_at * 1000 <= m.agent_last_seen_at * 1000
      );

      return this.splitMessages(read).filter(
        chat =>
          (chat.content !== null && chat.content !== '') ||
          chat.attachments ||
          chat.files ||
          CALL_TYPES.includes(chat.content_type)
      );
    },
    unReadMessages(m) {
      const unRead = m.messages.filter(
        chat => chat.created_at * 1000 > m.agent_last_seen_at * 1000
      );

      return this.splitMessages(unRead).filter(
        chat =>
          (chat.content !== null && chat.content !== '') ||
          chat.attachments ||
          chat.files ||
          CALL_TYPES.includes(chat.content_type)
      );
    },
  },
};
