var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"input-container"},[(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],class:{
      'error-border': _vm.shouldShowError && (_vm.isCharLimitExceeded || _vm.isCharMin),
    },style:(_vm.styles),attrs:{"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.internalValue)?_vm._i(_vm.internalValue,null)>-1:(_vm.internalValue)},on:{"input":_vm.onChange,"change":function($event){var $$a=_vm.internalValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.internalValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.internalValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.internalValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],class:{
      'error-border': _vm.shouldShowError && (_vm.isCharLimitExceeded || _vm.isCharMin),
    },style:(_vm.styles),attrs:{"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":"radio"},domProps:{"checked":_vm._q(_vm.internalValue,null)},on:{"input":_vm.onChange,"change":function($event){_vm.internalValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],class:{
      'error-border': _vm.shouldShowError && (_vm.isCharLimitExceeded || _vm.isCharMin),
    },style:(_vm.styles),attrs:{"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":_vm.type},domProps:{"value":(_vm.internalValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.internalValue=$event.target.value},_vm.onChange]}}),_vm._v(" "),_c('div',{staticClass:"char-counter",class:{
      'error-text': _vm.shouldShowError && (_vm.isCharLimitExceeded || _vm.isCharMin),
    }},[(_vm.isCharLimitExceeded)?_c('span',{staticClass:"char-limit-message"},[_vm._v("\n      "+_vm._s(_vm.getCharLimitMessage)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.isCharMin)?_c('span',{staticClass:"char-limit-message"},[_vm._v("\n      "+_vm._s(_vm.getCharMinMessage)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{class:{
        'char-count': true,
        'error-text': _vm.shouldShowError && (_vm.isCharLimitExceeded || _vm.isCharMin),
      }},[_vm._v("\n      "+_vm._s(_vm.charCount)+" / 30\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }