import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { escapeHtml, afterSanitizeAttributes } from './HTMLSanitizer';

const TWITTER_USERNAME_REGEX = /(^|[^@\w])@(\w{1,15})\b/g;
const TWITTER_USERNAME_REPLACEMENT =
  '$1<a href="http://twitter.com/$2" target="_blank" rel="noreferrer nofollow noopener">@$2</a>';

const TWITTER_HASH_REGEX = /(^|\s)#(\w+)/g;
const TWITTER_HASH_REPLACEMENT =
  '$1<a href="https://twitter.com/hashtag/$2" target="_blank" rel="noreferrer nofollow noopener">#$2</a>';

const USER_MENTIONS_REGEX = /mention:\/\/(user|team)\/(\d+)\/(.+)/gm;

class MessageFormatter {
  constructor(
    message,
    isATweet = false,
    isAPrivateNote = false,
    isAWhatsappChannel = false
  ) {
    this.message = DOMPurify.sanitize(escapeHtml(message || ''));
    this.isAPrivateNote = isAPrivateNote;
    this.isATweet = isATweet;
    this.marked = marked;
    this.isAWhatsappChannel = isAWhatsappChannel;

    const renderer = {
      heading(text) {
        return `<strong>${text}</strong>`;
      },
      link(url, title, text) {
        const mentionRegex = new RegExp(USER_MENTIONS_REGEX);
        if (url.match(mentionRegex)) {
          return `<span class="prosemirror-mention-node">${text}</span>`;
        }
        return `<a rel="noreferrer noopener nofollow" href="${url}" class="link" title="${title ||
          ''}" target="_blank">${text}</a>`;
      },
    };
    this.marked.use({ renderer });
  }

  /**
   * Formatea el mensaje, reemplazando el texto entre asteriscos con etiquetas HTML en negrita y aplicando formato adicional si es necesario.
   * @returns {string} El mensaje formateado.
   */
  formatMessage() {
    // Elimina el caracter \ que se inserta cuando se pega un texto con formato Markdown en el area de texto, ese caracter impedía que se formateara correctemente el texto
    this.message = this.message.replace(/\\/g, '');

    // Expresión regex que devuelve el texto que esté entre los símblos |||
    const underlineRegex = /\|\|\|([^\s][^|]*[^\s])\|\|\|/g;
    // Envuelve el texto encontrado en las etiquetas para subrayado de HTML (esta solucion solo si para los canales de WebChat porque no es soportada por los demas)
    this.message = this.message.replace(underlineRegex, '<u>$1</u>');

    // Si es un canal de Whatsapp
    if (this.isAWhatsappChannel) {
      // Expresión regex que devuelve el texto que esté entre los símblos *
      const boldTextRegex = /\*([^*]+)\*/g;
      // Envuelve el texto encontrado en las etiquetas para negrita de HTML (solo se aplica en estos canales porque no usan el estandar de Markdown para negritas)
      this.message = this.message.replace(boldTextRegex, '<strong>$1</strong>');
    }
    if (this.isATweet && !this.isAPrivateNote) {
      // const withUserName = formattedMessage.replace(
      const withUserName = this.message.replace(
        TWITTER_USERNAME_REGEX,
        TWITTER_USERNAME_REPLACEMENT
      );
      const withHash = withUserName.replace(
        TWITTER_HASH_REGEX,
        TWITTER_HASH_REPLACEMENT
      );
      const markedDownOutput = marked(withHash);
      return markedDownOutput;
    }
    DOMPurify.addHook('afterSanitizeAttributes', afterSanitizeAttributes);
    return DOMPurify.sanitize(
      marked(this.message, { breaks: true, gfm: true })
    );
  }

  get formattedMessage() {
    return this.formatMessage();
  }

  get plainText() {
    const strippedOutHtml = new DOMParser().parseFromString(
      this.formattedMessage,
      'text/html'
    );
    return strippedOutHtml.body.textContent || '';
  }
}

export default MessageFormatter;
