export const isPhoneE164 = value => !!value.match(/^\+[1-9]\d{1,14}$/);
export const isPhoneE164OrEmpty = value => isPhoneE164(value) || value === '';

/**
 * Comprueba si un número de teléfono cumple con el formato E.164 sin el signo "+".
 *
 * @function
 * @name isPhoneE164WithOutPlus
 * @param {string} value - El número de teléfono a validar.
 * @returns {boolean} Devuelve true si el número de teléfono cumple con el formato E.164 sin el signo "+", de lo contrario, devuelve false.
 */
export const isPhoneE164WithOutPlus = value => {
  if (value === undefined || value === null) {
    return true;
  }
  return !!value.match(/^[1-9]\d{1,14}$/);
};

/**
 * Comprueba si un número de teléfono cumple con el formato E.164 sin el signo "+" o si está vacío.
 *
 * @function
 * @name isPhoneE164OrEmptyWithOutPlus
 * @param {string} value - El número de teléfono a validar.
 * @returns {boolean} Devuelve true si el número de teléfono cumple con el formato E.164 sin el signo "+" o si está vacío, de lo contrario, devuelve false.
 */
export const isPhoneE164OrEmptyWithOutPlus = value => {
  if (value === undefined || value === null) {
    return true;
  }
  return isPhoneE164WithOutPlus(value) || value === '';
};

/**
 * Comprueba si una extensión es válida.
 *
 * @function
 * @name isExtensionValid
 * @param {string} value - La extensión a validar.
 * @returns {boolean} Devuelve true si la extensión es válida según el patrón especificado, de lo contrario, devuelve false.
 */
export const isExtensionValid = value => /^[a-zA-Z0-9-_/./]{0,}$/.test(value);

export const shouldBeUrl = (value = '') =>
  value ? value.startsWith('http') : true;
export const isValidPassword = value => {
  const containsUppercase = /[A-Z]/.test(value);
  const containsLowercase = /[a-z]/.test(value);
  const containsNumber = /[0-9]/.test(value);
  const containsSpecialCharacter = /[!@#$%^&*()_+\-=[\]{}|'"/\\.,`<>:;?~]/.test(
    value
  );
  return (
    containsUppercase &&
    containsLowercase &&
    containsNumber &&
    containsSpecialCharacter
  );
};
export const isNumber = value => /^\d+$/.test(value);
export const isDomain = value => {
  if (value !== '') {
    const domainRegex = /^([\p{L}0-9]+(-[\p{L}0-9]+)*\.)+[a-z]{2,}$/gmu;
    return domainRegex.test(value);
  }
  return true;
};
/**
 * Validaciones adicionales para formato de URL
 */
export const additionalUrlValidators = value => {
  if (value) {
    return !value.endsWith('.');
  }

  return true;
};
