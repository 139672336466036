import { render, staticRenderFns } from "./NotificationPanel.vue?vue&type=template&id=43d67af4&scoped=true&"
import script from "./NotificationPanel.vue?vue&type=script&lang=js&"
export * from "./NotificationPanel.vue?vue&type=script&lang=js&"
import style0 from "./NotificationPanel.vue?vue&type=style&index=0&id=43d67af4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d67af4",
  null
  
)

export default component.exports