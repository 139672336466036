<template>
  <div>
    <div
      class="reply-to-container"
      :class="{
        'incoming-message': messageType === MESSAGE_TYPE.INCOMING,
        'outgoing-message': messageType === MESSAGE_TYPE.OUTGOING,
        'no-attachments': !parentHasAttachments,
      }"
    >
      <message-preview
        :message="message"
        :show-message-type="false"
        :default-empty-message="$t('CONVERSATION.REPLY_MESSAGE_NOT_FOUND')"
      />
    </div>
    <div class="separator" />
  </div>
</template>

<script>
import MessagePreview from 'dashboard/components/widgets/conversation/MessagePreview.vue';
import { MESSAGE_TYPE } from 'shared/constants/messages';

export default {
  name: 'ReplyTo',
  components: {
    MessagePreview,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    messageType: {
      type: Number,
      required: true,
    },
    parentHasAttachments: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { MESSAGE_TYPE };
  },
};
</script>

<style scoped>
.reply-to-container {
  border-radius: 0.25rem;
  min-width: 10rem;
}

.separator {
  height: 1px;
  background-color: var(--conversa2-neutral-100-color);
  margin: 0 -1.6rem;
}

.incoming-message {
  opacity: 0.8;
  border: 1rem;
  padding-bottom: 1.2rem;
  border-radius: 6px;
  color: var(--conversa2-neutral-400-color);
}

.outgoing-message {
  background-color: #6b7280;
  color: var(--conversa2-blue-700-color);
}

.no-attachments {
  margin: 0;
}
</style>
