<template>
  <div v-if="extensionFile === 'ogx'">
    <download-file :url="url" />
  </div>
  <div v-else class="video message-text__wrap">
    <video
      ref="video"
      :src="url"
      muted
      playsInline
      @loadedmetadata="handleLoadedMetadata"
      @click="onClick"
      @error="onError"
    />
    <div class="play-square" @click="onClick">
      <fluent-icon icon="play-square" size="24" />
    </div>
    <div
      class="video-icon"
      :class="{
        'has-status-icon':
          showSentIndicator || showDeliveredIndicator || showReadIndicator,
      }"
    >
      <fluent-icon icon="video-icon" size="14" viewbox="0 0 14 14" />
      <span class="video-time">{{ videoDurationTime }}</span>
    </div>
    <woot-modal :show.sync="show" :on-close="onClose">
      <video :src="url" controls playsInline class="modal-video" />
    </woot-modal>
  </div>
</template>

<script>
import { getUrlExtension } from '../../../../../shared/constants/messages';
import DownloadFile from './integrations/Download.vue';
import { MESSAGE_TYPE, MESSAGE_STATUS } from 'shared/constants/messages';
import inboxMixin from 'shared/mixins/inboxMixin';
import { mapGetters } from 'vuex';

export default {
  components: {
    'download-file': DownloadFile,
  },
  mixins: [inboxMixin],
  props: {
    url: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    createdAt: {
      type: Number,
      default: 0,
    },
    isPrivate: {
      type: Boolean,
      default: true,
    },
    messageType: {
      type: Number,
      default: 1,
    },
    messageStatus: {
      type: String,
      default: '',
    },
    sourceId: {
      type: String,
      default: '',
    },
    inboxId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      retries: 0,
      videoDuration: 0,
      showStatus: false,
    };
  },
  computed: {
    /**
     * Obtiene el chat seleccionado actual desde el store de Vuex.
     * @returns {Object} El chat seleccionado actual.
     */
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),

    /**
     * Obtiene el objeto de la bandeja de entrada desde el store de Vuex usando el inboxId.
     * @returns {Object} El objeto de la bandeja de entrada.
     */
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },

    /**
     * Verifica si el tipo de mensaje es INCOMING.
     * @returns {boolean} Verdadero si el mensaje es entrante, falso de lo contrario.
     */
    isIncoming() {
      return MESSAGE_TYPE.INCOMING === this.messageType;
    },

    /**
     * Verifica si el tipo de mensaje es OUTGOING.
     * @returns {boolean} Verdadero si el mensaje es saliente, falso de lo contrario.
     */
    isOutgoing() {
      return MESSAGE_TYPE.OUTGOING === this.messageType;
    },

    /**
     * Verifica si el tipo de mensaje es TEMPLATE.
     * @returns {boolean} Verdadero si el mensaje es una plantilla, falso de lo contrario.
     */
    isTemplate() {
      return MESSAGE_TYPE.TEMPLATE === this.messageType;
    },

    /**
     * Verifica si el estado del mensaje es DELIVERED.
     * @returns {boolean} Verdadero si el mensaje está entregado, falso de lo contrario.
     */
    isDelivered() {
      return MESSAGE_STATUS.DELIVERED === this.messageStatus;
    },

    /**
     * Verifica si el estado del mensaje es READ.
     * @returns {boolean} Verdadero si el mensaje está leído, falso de lo contrario.
     */
    isRead() {
      return MESSAGE_STATUS.READ === this.messageStatus;
    },

    /**
     * Verifica si el estado del mensaje es SENT.
     * @returns {boolean} Verdadero si el mensaje está enviado, falso de lo contrario.
     */
    isSent() {
      return MESSAGE_STATUS.SENT === this.messageStatus;
    },

    /**
     * Determina si se deben mostrar los indicadores de estado.
     * @returns {boolean} Verdadero si se deben mostrar los indicadores de estado, falso de lo contrario.
     */
    showStatusIndicators() {
      if ((this.isOutgoing || this.isTemplate) && !this.isPrivate) {
        return true;
      }
      return false;
    },

    /**
     * Determina si se debe mostrar el indicador de enviado.
     * @returns {boolean} Verdadero si se debe mostrar el indicador de enviado, falso de lo contrario.
     */
    showSentIndicator() {
      if (!this.showStatusIndicators) {
        return false;
      }

      if (this.isAnEmailChannel) {
        return !!this.sourceId;
      }

      if (this.isAWhatsAppChannel) {
        return this.sourceId && this.isSent;
      }
      return false;
    },

    /**
     * Determina si se debe mostrar el indicador de entregado.
     * @returns {boolean} Verdadero si se debe mostrar el indicador de entregado, falso de lo contrario.
     */
    showDeliveredIndicator() {
      if (!this.showStatusIndicators) {
        return false;
      }

      if (this.isAWhatsAppChannel) {
        return this.sourceId && this.isDelivered;
      }

      return false;
    },

    /**
     * Determina si se debe mostrar el indicador de leído.
     * @returns {boolean} Verdadero si se debe mostrar el indicador de leído, falso de lo contrario.
     */
    showReadIndicator() {
      if (!this.showStatusIndicators) {
        return false;
      }

      if (this.isAWebWidgetInbox) {
        const { contact_last_seen_at: contactLastSeenAt } = this.currentChat;
        return contactLastSeenAt >= this.createdAt;
      }

      if (this.isAWhatsAppChannel) {
        return this.sourceId && this.isRead;
      }
      return false;
    },
    extensionFile() {
      return getUrlExtension(this.url);
    },
    bubble() {
      return document.getElementById(`conversation-${this.id}`);
    },
    videoDurationTime() {
      return this.videoDuration !== 0
        ? new Date(this.videoDuration * 1000).toISOString().substring(14, 19)
        : '00:00';
    },
  },
  mounted() {
    this.onSetBubble();
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
    handleLoadedMetadata() {
      // Actualiza la duración del video cuando se cargan los metadatos
      this.videoDuration = this.$refs.video.duration;
    },
    /**
     * Método para manejo de error al cargar video
     */
    onError() {
      let video = this.$refs.video;
      if (
        video.error.code === video.error.MEDIA_ERR_SRC_NOT_SUPPORTED &&
        video.readyState === video.HAVE_NOTHING &&
        video.networkState === video.NETWORK_NO_SOURCE
      ) {
        if (this.retries < 10) {
          let time = `?${new Date().getTime()}`;
          setTimeout(() => {
            video.src = `${video.src}${time}`;
            video.load();
            this.retries += 1;
          }, 500);
        }
      }
    },
    /**
     * Para eliminar el estilo en la burbuja del video tipo ogx
     */
    onSetBubble() {
      if (this.extensionFile === 'ogx') {
        this.bubble.className = 'bubble';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.play-square {
  position: absolute;
  background: rgba(17, 17, 18, 0.40);
  color: var(--white);
  display: flex;
  width: 4rem;
  height: 4rem;
  padding: var(--space-small);
  justify-content: center;
  align-items: center;
  border-radius: var(--space-smaller);
}
.video-icon {
  position: absolute;
  bottom: 0.6rem;
  color: var(--white);
  right: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-smaller);
  &.has-status-icon {
    right: 4rem;
  }
  .video-time {
    line-height: var(--space-normal-plus);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-micro);
  }
}
</style>
