<template>
  <!-- New buttons based on contentAttributes.items -->
  <div v-if="contentAttributes && contentAttributes.items">
    <div v-for="(item, index) in contentAttributes.items" :key="index">
      <button
        v-if="contentAttributes.items.length <= 3"
        class="option-button"
        :disabled="true"
      >
        <span class="option-text">{{ item.title }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    contentAttributes: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.option-button {
  margin-top: var(--space-smaller);
  background-color: white;
  color: var(--conversa2-blue-700-color);
  border: 1px solid var(--conversa2-blue-300-color);
  padding: var(--space-small) var(--space-one);
  border-radius: var(--border-radius-normal-small);
  transition: background-color 0.3s ease;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.option-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 16px;
  text-align: center;
  font-size: var(--font-size-mini);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
</style>
