<template>
  <div class="transcription-list">
    <div
      v-for="(line, index) in transcriptions"
      :key="index"
      :class="getClassName(line.sender_type)"
    >
      <woot-thumbnail
        v-if="hasSenderType(line.sender_type)"
        :src="getSenderThumbnail(line.sender_type)"
        :username="getSenderName(line.sender_type)"
        size="24px"
      />
      <div v-if="hasSenderType(line.sender_type)" class="details" style="">
        <div style="align-self: stretch">
          <span class="name">{{ getSenderName(line.sender_type) }}: </span>
          <span class="content" v-html="nl2br(line.content)" />
        </div>
      </div>
      <div v-else v-html="nl2br(line.content)" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    /**
     * Callback para cerrar el componente.
     * @type {Function}
     * @default () => {}
     */
    onClose: {
      type: Function,
      default: () => {},
    },
    /**
     * El ID del mensaje.
     * @type {string}
     * @default ''
     */
    messageId: {
      type: String,
      default: '',
    },
    /**
     * Atributos de contenido del mensaje.
     * @type {Object}
     * @default {}
     */
    contentAttributes: {
      type: Object,
      default: () => ({}),
    },
    /**
     * El ID de la conversación.
     * @type {string}
     * @default ''
     */
    conversationId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      /**
       * Obtiene las transcripciones de la conversación.
       * @function transcriptions
       * @memberof module:TranscriptionList
       * @type {Function}
       */
      transcriptions: 'getTranscriptions',
      /**
       * Obtiene el chat actualmente seleccionado.
       * @function currentChat
       * @memberof module:TranscriptionList
       * @type {Function}
       */
      currentChat: 'getSelectedChat',
    }),
  },
  mounted() {
    this.$store.dispatch('transcriptMessage', {
      conversationId: this.conversationId,
      messageId: this.messageId,
    });
  },
  methods: {
    /**
     * Reemplaza todos los caracteres de nueva línea en el contenido con etiquetas HTML <br>.
     *
     * Este método convierte los saltos de línea (nuevas líneas) en una cadena a elementos HTML <br>.
     * Soporta diferentes tipos de caracteres de nueva línea: \r\n (Windows), \n (Unix) y \r (Mac antiguo).
     *
     * @function nl2br
     * @memberof module:TranscriptionList
     * @param {string} content - El contenido en el que se reemplazarán las nuevas líneas con etiquetas <br>.
     * @returns {string} - El contenido con los caracteres de nueva línea reemplazados por etiquetas <br>.
     *                     Si el contenido está vacío o indefinido, devuelve una cadena vacía.
     */
    nl2br(content) {
      if (!content) return '';
      return content.replace(/(\r\n|\n|\r)/g, '<br>');
    },
    /**
     * Obtiene la miniatura del remitente.
     * @function getSenderThumbnail
     * @memberof module:TranscriptionList
     * @param {string} senderType - El tipo de remitente (User o Contact).
     * @returns {string} La URL de la miniatura del remitente o una cadena vacía si no está presente.
     */
    getSenderThumbnail(senderType) {
      if (senderType === 'User') {
        return this.contentAttributes.custom_sender.thumbnail || '';
      }
      if (senderType === 'Contact') {
        return this.currentChat.meta.sender.thumbnail || '';
      }
      return '';
    },
    /**
     * Obtiene el nombre del remitente.
     * @function getSenderName
     * @memberof module:TranscriptionList
     * @param {string} senderType - El tipo de remitente (User o Contact).
     * @returns {string} El nombre del remitente o una cadena vacía si no está presente.
     */
    getSenderName(senderType) {
      if (senderType === 'User') {
        return this.contentAttributes.custom_sender.name || '';
      }
      if (senderType === 'Contact') {
        return this.currentChat.meta.sender.name || '';
      }
      return '';
    },
    /**
     * Obtener el nombre de la clase del renglon
     */
    getClassName(senderType) {
      if (senderType === 'User') {
        return 'transcription-line sender-type-user';
      }
      if (senderType === 'Contact' || senderType === 'undefined') {
        return 'transcription-line sender-type-contact';
      }
      return '';
    },
    /**
     * Indica si el tipo de sender no es indefinido para identificar el tipo transcripcion
     */
    hasSenderType(senderType) {
      return senderType !== 'undefined';
    },
  },
};
</script>

<style>
#modal-transcript .modal-container .transcription-line .details {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

#modal-transcript .modal-container .avatar {
  width: Hug (24px);
  height: Hug (24px);
  gap: 4px;
}

#modal-transcript .modal-container .transcription-line .content {
  padding: 0px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--conversa2-neutral-800-color);
}

#modal-transcript .modal-container .transcription-line .name {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--conversa2-neutral-800-color);
}

#modal-transcript .transcription-list {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 0 32px 0 32px;
}

#modal-transcript .transcription-line {
  display: flex;
  margin: 0 0 4px 0;
  color: var(--conversa2-neutral-800-color);
  width: 100%;
  padding: 4px;
  border: 0px;
  gap: 8px;
}

#modal-transcript .transcription-line.sender-type-user {
  background-color: var(--conversa2-blue-50-color);
  border-radius: 4px;
}

#modal-transcript .transcription-line.sender-type-contact {
  background-color: transparent;
}
</style>
