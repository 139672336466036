<template>
  <woot-modal id="modal-transcript" :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <transcription-head
        :message-id="messageId"
        :content-attributes="contentAttributes"
      />
      <transcription-list
        :conversation-id="conversationId"
        :message-id="messageId"
        :content-attributes="contentAttributes"
      />
    </div>
    <div class="modal-footer modal-footer-transcript">
      <button class="button footer-button-back" @click.prevent="onClose">
        {{ $t('CONVERSATION.TRANSCRIPTION.BACK') }}
      </button>
    </div>
  </woot-modal>
</template>

<script>
import TranscriptionHead from './TranscriptionHead.vue';
import TranscriptionList from './TranscriptionList.vue';

export default {
  components: { TranscriptionHead, TranscriptionList },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    messageId: {
      type: String,
      default: '',
    },
    contentAttributes: {
      type: Object,
      default: () => ({}),
    },
    conversationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style>
#modal-transcript .modal--close {
  top: 32px;
  right: 32px;
  width: 24px;
  height: 24px;
  color: var(--conversa2-neutral-800-color);
}

#modal-transcript .modal-container {
  width: 834px;
}

#modal-transcript .modal-container .modal-footer-transcript {
  padding: 0 32px 32px 32px;
  margin-top: 24px;
}

#modal-transcript .modal-container .modal-footer .footer-button-back {
  width: 103px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 0px;
}
</style>
