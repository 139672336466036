export const REPLY_EDITOR_MODES = {
  REPLY: 'REPLY',
  NOTE: 'NOTE',
};

export const CHAR_LENGTH_WARNING = {
  UNDER_50: 'characters remaining',
  NEGATIVE: 'characters over',
};

/**
 * La función devuelve un objeto con etiquetas de información sobre herramientas para varios botones, traducidas según el
 * idioma proporcionado.
 * @param {function} translate - El locale configurado del usuario
 * @returns {{strong: string, emphasis: string, code: string, link: string, orderedList: string, bulletList: string, undo: string, redo: string,}} El objeto traducido de los botones
 */
export const tooltipLabelsButtons = (translate) => {
  return {
    strong: translate.t('EDITOR-WRITER.STRONG'),
    emphasis: translate.t('EDITOR-WRITER.EMPHASIS'),
    code: translate.t('EDITOR-WRITER.CODE'),
    link: translate.t('EDITOR-WRITER.LINK'),
    orderedList: translate.t('EDITOR-WRITER.ORDERED_LIST'),
    bulletList: translate.t('EDITOR-WRITER.BULLET_LIST'),
    undo: translate.t('EDITOR-WRITER.UNDO'),
    redo: translate.t('EDITOR-WRITER.REDO'),
    createLink: translate.t('EDITOR-WRITER.CREATE_LINK'),
    placeholderLink: translate.t('EDITOR-WRITER.PLACEHOLDER_LINK'),
    btnCancel: translate.t('EDITOR-WRITER.BTN_CANCEL'),
    btnCreateLink: translate.t('EDITOR-WRITER.BNT_CREATE'),
  };
}