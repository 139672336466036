import { fromUnixTime, format, formatDistanceToNow } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { capitalizeFirstLetter, getTimeFormat } from '../helper/commons';

export default {
  methods: {
    /**
     * Función que toma un DateTime, formato de fecha y localidad(i18n language). Luego convierte el DateTime en una hora UNIX y la cambia al formato e idioma solicitado.
     *
     * @param { DateTime } time
     * @param {string} [dateFormat='h:mm a']
     * @param {string} [locale='es/en/etc']
     * @return { string }
     */
    messageStamp(time, dateFormat, locale = 'es') {
      const unixTime = fromUnixTime(time);
      return capitalizeFirstLetter(
        format(unixTime, dateFormat, { locale: locale === 'es' ? es : enUS })
      );
    },
    dynamicTime(time, locale = 'en') {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, {
        addSuffix: true,
        locale: locale === 'es' ? es : enUS,
      });
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    shortTimestamp(time) {
      const convertToShortTime = time
        .replace(/about|over|almost|/g, '')
        .replace('less than a minute ago', 'now')
        .replace(' minute ago', 'm')
        .replace(' minutes ago', 'm')
        .replace('a minute ago', 'm')
        .replace('an hour ago', 'h')
        .replace(' hour ago', 'h')
        .replace(' hours ago', 'h')
        .replace(' day ago', 'd')
        .replace('a day ago', 'd')
        .replace(' days ago', 'd')
        .replace('a month ago', 'mo')
        .replace(' months ago', 'mo')
        .replace(' month ago', 'mo')
        .replace('a year ago', 'y')
        .replace(' year ago', 'y')
        .replace(' years ago', 'y');
      return convertToShortTime;
    },
    /** Reemplaza ciertas cadenas en la cadena de marca de tiempo con sus correspondientes traducciones del objeto de traducción. Esto se utiliza para mostrar una versión más fácil de usar y localizada del marca de tiempo en la interfaz de usuario.
     * @param { DateTime } time - timestamp
     * @param { function } translate - Funcion locale confiurado del usuario
     */
    shortInfoTimestamp(time, translate) {
      const convertToShortTime = time
        .replace(/about/, '')
        .replace(
          'less than a minute ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.MOMENT_AGO')
        )
        .replace(
          'minute ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.MINUTE_AGO')
        )
        .replace(
          'minutes ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.MINUTES_AGO')
        )
        .replace(
          'a minute ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.A_MINUTE_AGO')
        )
        .replace(
          'an hour ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.AN_HOUR_AGO')
        )
        .replace(
          'hour ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.HOUR_AGO')
        )
        .replace(
          'hours ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.HOURS_AGO')
        )
        .replace(
          'day ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.DAY_AGO')
        )
        .replace(
          'a day ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.A_DAY_AGO')
        )
        .replace(
          'days ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.DAYS_AGO')
        )
        .replace(
          'a month ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.A_MONTH_AGO')
        )
        .replace(
          'months ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.MONTHS_AGO')
        )
        .replace(
          'month ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.MONTH_AGO')
        )
        .replace(
          'a year ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.A_YEAR_AGO')
        )
        .replace(
          'year ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.YEAR_AGO')
        )
        .replace(
          'years ago',
          translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.YEARS_AGO')
        );
      return `${translate.t('CONTACT_PANEL.METRICS_TIMESTAMP.SINCE')} ${convertToShortTime}`;
    },
    datetimeForDisplay(timestamp, yesterdayTranslate, locale = 'es') {
      const currentDate = new Date();
      const previewConversationDate = fromUnixTime(timestamp);
      return getTimeFormat(
        currentDate,
        previewConversationDate,
        yesterdayTranslate,
        locale
      );
    },
    /**
     * Devuelve un nuevo formato de la fecha de inicio de la conversacion
     * @param {date} timestamp - El timestamp de la conversacion
     * @param {string} locale - La configuración regional del usuario.
     */
    timeZoneLocal(str, locale = 'es') {
      const date = new Date(str);
      const day = capitalizeFirstLetter(
        format(date, 'EEE', { locale: locale === 'es' ? es : enUS })
      );
      const month = capitalizeFirstLetter(
        format(date, 'MMM', { locale: locale === 'es' ? es : enUS })
      );
      const numberDay = format(date, 'dd');
      const year = format(date, 'yyyy');
      const hours = format(date, 'HH:mm');
      const timeZone = format(date, 'zzzz');
      const zone = date
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2];
      const timestampString = `${day} ${month} ${numberDay} ${year} ${hours} ${timeZone} ${zone}`;
      return timestampString;
    },
    /** Método para cambiar el idioma en el nombre del mes (el fichero de idioma es dates.json y solo existe para español e ingles) */
    formatDateByLocale(datetimestamp, stringFormat) {
      let dateFormatted = format(fromUnixTime(datetimestamp), stringFormat);
      const arrayDate = dateFormatted.split('-');

      if (arrayDate.length >= 2 && arrayDate.length <= 3) {
        let month =
          arrayDate.length === 2
            ? arrayDate[0].toUpperCase()
            : arrayDate[1].toUpperCase();
        dateFormatted =
          arrayDate.length === 2
            ? `${this.$t(`DATES.MONTHS.${month}`)}-${arrayDate[1]}`
            : `${arrayDate[0]}-${this.$t(`DATES.MONTHS.${month}`)}-${
                arrayDate[2]
              }`;
      }

      return dateFormatted;
    },
  },
};
