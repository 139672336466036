<template>
  <div class="code--container">
    <button class="button small button--copy-code" @click="onCopy">
      <span class="span--copy-code">{{
        $t('COMPONENTS.CODE.BUTTON_TEXT')
      }}</span>
    </button>
    <highlightjs class="wrapper-code" v-if="script" :language="lang" :code="script" />
  </div>
</template>

<script>
import 'highlight.js/styles/default.css';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

export default {
  props: {
    script: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: 'javascript',
    },
  },
  methods: {
    async onCopy(e) {
      e.preventDefault();
      await copyTextToClipboard(this.script);
      bus.$emit('newToastMessage', this.$t('COMPONENTS.CODE.COPY_SUCCESSFUL'));
    },
  },
};
</script>

<style lang="scss" scoped>
.code--container {
  position: relative;
  text-align: left;
  width: 100%;
  background-color: #F1F5F8;
  border-radius: 12px;

  .button--copy-code {
    margin-top: 0;
    position: absolute;
    top: var(--space-slab);
    right: var(--space-slab);
    display: inline-flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 5.8rem;
    height: 3.4rem;
    border-radius: 0.4rem;
  }
  .span--copy-code {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .wrapper-code {
    margin-right: 82px;
  }
}
</style>
