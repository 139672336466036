<template>
  <div class="row empty-state">
    <div v-if="showIcon" class="container-icon">
      <fluent-icon :icon="icon" />
    </div>
    <h3 class="title">
      {{ title }}
    </h3>
    <p class="message">
      {{ message }}
    </p>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    icon: { type: String, default: '' },
  },
  computed: {
    showIcon() {
      return this.icon !== '';
    },
  },
};
</script>

<style lang="scss" scoped>
.container-icon {
  width: 100%;
  text-align: center;
}

.container-icon svg {
  width: 200px;
  height: 200px;
}
</style>
