var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conversation",class:{
    active: _vm.isActiveChat,
    'unread-chat': _vm.hasUnread,
    'has-inbox-name': _vm.showInboxName,
    'conversation-selected': _vm.selected,
  },on:{"mouseenter":_vm.onCardHover,"mouseleave":_vm.onCardLeave,"click":function($event){return _vm.cardClick(_vm.chat)},"contextmenu":function($event){return _vm.openContextMenu($event)}}},[(_vm.hovered || _vm.selected)?_c('label',{staticClass:"checkbox-wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('input',{staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"value":_vm.selected,"checked":_vm.selected},on:{"change":function($event){return _vm.onSelectConversation($event.target.checked)}}})]):_vm._e(),_vm._v(" "),(_vm.bulkActionCheck)?_c('thumbnail',{staticClass:"columns",attrs:{"src":_vm.currentContact.thumbnail,"badge":_vm.inboxBadge,"username":_vm.currentContact.name,"status":_vm.currentContact.availability_status,"size":"40px"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"conversation--details columns"},[_c('div',{staticClass:"conversation--metadata"},[(_vm.showInboxName)?_c('inbox-name',{attrs:{"inbox":_vm.inbox}}):_vm._e(),_vm._v(" "),_c('inbox-agent-name',{attrs:{"name-agent":_vm.assignee.name}})],1),_vm._v(" "),_c('h4',{staticClass:"conversation--user"},[_vm._v("\n      "+_vm._s(_vm.currentContact.name || ' ')+"\n    ")]),_vm._v(" "),(_vm.lastMessageInChat)?_c('p',{class:_vm.callLastMessageClass},[(
          _vm.lastMessageInChat.content_attributes &&
            _vm.lastMessageInChat.content_attributes.items &&
            _vm.lastMessageInChat.content_attributes.items.length > 0
        )?_c('fluent-icon',{staticClass:"message--attachment-icon interactive-message-icon-class",attrs:{"size":"16","icon":"interactive-message-icon"}}):(_vm.isMessagePrivate)?_c('fluent-icon',{staticClass:"message--attachment-icon last-message-icon",attrs:{"size":"16","icon":"lock-closed"}}):(_vm.shouldShowCallSpan)?_c('fluent-icon',{class:_vm.callIconClass,attrs:{"size":20,"icon":_vm.getCallIcon,"type":"solid","viewbox":"0 0 20 20"}}):(_vm.messageByAgent)?_c('fluent-icon',{staticClass:"message--attachment-icon last-message-icon",attrs:{"size":"16","icon":"arrow-reply"}}):(_vm.isMessageAnActivity)?_c('fluent-icon',{staticClass:"message--attachment-icon last-message-icon",attrs:{"size":"16","icon":"info"}}):_vm._e(),_vm._v(" "),(
          _vm.lastMessageInChat.content_attributes &&
            _vm.lastMessageInChat.content_attributes.items &&
            _vm.lastMessageInChat.content_attributes.items.length > 0
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('CHAT_LIST.INTERACTIVE_MESSAGE'))+"\n      ")]):(_vm.shouldShowCallSpan)?_c('span',{staticClass:"call_last_message"},[_vm._v("\n        "+_vm._s(_vm.parsedLastMessage)+"\n      ")]):(_vm.lastMessageInChat.content)?_c('span',[_vm._v("\n        "+_vm._s(_vm.parsedLastMessage)+"\n      ")]):(_vm.lastMessageInChat.attachments)?_c('span',[(_vm.attachmentIcon)?_c('fluent-icon',{staticClass:"message--attachment-icon",attrs:{"size":"16","icon":_vm.attachmentIcon}}):_vm._e(),_vm._v("\n        "+_vm._s(_vm.attachmentMessageContent)+"\n      ")],1):(_vm.inboxBadge === 'instagram_direct_message')?_c('span',[_vm._v("\n        "+_vm._s(this.$t('CONVERSATION.NO_SUPPORTED_MESSAGE', {
            channel: 'Instagram',
          }))+"\n      ")]):_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('CHAT_LIST.NO_CONTENT'))+"\n      ")])],1):_c('p',{staticClass:"conversation--message"},[_c('fluent-icon',{staticClass:"message--attachment-icon",attrs:{"size":"16","icon":"info"}}),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(this.$t("CHAT_LIST.NO_MESSAGES"))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"conversation--meta"},[_c('span',{staticClass:"timestamp"},[_c('time-ago',{attrs:{"last-activity-timestamp":_vm.chat.timestamp,"created-at-timestamp":_vm.chat.created_at}})],1),_vm._v(" "),_c('span',{staticClass:"unread"},[_vm._v(_vm._s(_vm.unreadCount > 99 ? '99+' : _vm.unreadCount))])]),_vm._v(" "),_c('card-labels',{attrs:{"conversation-id":_vm.chat.id}})],1),_vm._v(" "),(_vm.showContextMenu)?_c('woot-context-menu',{ref:"menu",attrs:{"x":_vm.contextMenu.x,"y":_vm.contextMenu.y},on:{"close":_vm.closeContextMenu}},[_c('conversation-context-menu',{attrs:{"status":_vm.chat.status,"inbox-id":_vm.inbox.id,"has-unread-messages":_vm.hasUnread},on:{"update-conversation":_vm.onUpdateConversation,"assign-agent":_vm.onAssignAgent,"assign-label":_vm.onAssignLabel,"assign-team":_vm.onAssignTeam,"mark-as-unread":_vm.markAsUnread}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }