<template>
  <div class="call-bubble">
    <div class="header-section">
      <svg
        class="call-bubble-svg"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="40"
        height="40"
      >
        <ellipse cx="20" cy="20" rx="20" ry="20" :fill="ellipseFill" />
        <g transform="translate(20 20)">
          <g :transform="iconTransform">
            <fluent-icon
              :icon="callIcon"
              type="solid"
              :class="iconClass"
              :width="iconWidth"
              :height="iconHeight"
              :viewbox="iconViewBox"
            />
          </g>
        </g>
      </svg>

      <div class="event-details">
        <p class="event-title">{{ contentAttributes.title }}</p>
        <p class="event-detail">{{ contentAttributes.detail }}</p>
      </div>
    </div>
    <div>
      <p v-if="contentAttributes.summary" class="event-summary-title">
        {{ $t('CONVERSATION.CALL_BUBBLE.SUMMARY_TITLE') }}
      </p>
      <p v-if="contentAttributes.summary" class="event-summary">
        {{ contentAttributes.summary }}
      </p>
      <div v-if="hasTranscripts" class="full-transcript-container">
        <a class="full-transcript-link" @click="openTranscriptPopup()">{{
          $t('CONVERSATION.CALL_BUBBLE.FULL_TRANSCRIPT_LINK')
        }}</a>
      </div>
    </div>

    <div v-if="hasAttachments" class="audio-section">
      <div class="message-text__wrap">
        <p class="recording-title">
          {{ $t('CONVERSATION.CALL_BUBBLE.RECORDING_TITLE') }}
        </p>
        <custom-audio
          :message-id="messageId"
          :total-duration="Number(totalDuration)"
          :source="attachments[attachments.length - 1].data_url"
        />
      </div>
    </div>
    <woot-modal
      :show.sync="showTranscriptPopup"
      :on-close="hideTranscriptPopup"
    >
      <transcription
        :conversation-id="conversationId"
        :message-id="messageId"
        :content-attributes="contentAttributes"
        :on-close="hideTranscriptPopup"
      />
    </woot-modal>
  </div>
</template>

<script>
import FluentIcon from 'shared/components/FluentIcon/Index.vue';
import CustomAudio from '../CustomAudio.vue';
import Transcription from '../transcription/Transcription.vue';
import {
  MESSAGE_TYPE,
  MESSAGE_CONTENT_TYPE,
  CALL_ICONS,
  CALL_ICON_CLASS,
  ELLIPSE_COLORS,
} from 'widget/helpers/constants';

export default {
  components: { FluentIcon, CustomAudio, Transcription },
  props: {
    messageType: {
      type: Number,
      default: 1,
    },
    messageContentType: {
      type: String,
      default: '',
    },
    contentAttributes: {
      type: Object,
      default: () => ({}),
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    messageId: {
      type: String,
      default: '',
    },
    conversationId: {
      type: String,
      default: '',
    },
    inboxId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showTranscriptPopup: false,
    };
  },
  computed: {
    /**
     * Se obtiene el inbox
     */
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    /**
     * Obtiene la duración total del audio, en segundos.
     */
    totalDuration() {
      return this.contentAttributes.recording_duration || 0;
    },
    /**
     * Devuelve la cadena de vista de SVG en función del estado de error de la llamada.
     * @returns {string} La cadena de vista de SVG correspondiente.
     */
    svgViewBox() {
      return this.isErrorCall ? '0 0 20 24' : '0 0 40 40';
    },

    /**
     * Devuelve la transformación del ícono en función del estado de error de la llamada.
     * @returns {string} La cadena de transformación correspondiente.
     */
    iconTransform() {
      return this.isErrorCall
        ? `translate(${-20 / 2} ${-24 / 2})`
        : `translate(${-17 / 2} ${-17 / 2})`;
    },

    /**
     * Devuelve el ancho del ícono en función del estado de error de la llamada.
     * @returns {string} El ancho del ícono correspondiente.
     */
    iconWidth() {
      return this.isErrorCall ? '20' : '17px';
    },

    /**
     * Devuelve la altura del ícono en función del estado de error de la llamada.
     * @returns {string} La altura del ícono correspondiente.
     */
    iconHeight() {
      return this.isErrorCall ? '24' : '17px';
    },

    /**
     * Devuelve la cadena de vista de ícono en función del estado de error de la llamada.
     * @returns {string} La cadena de vista de ícono correspondiente.
     */
    iconViewBox() {
      return this.isErrorCall ? '0 0 20 24' : '0 0 17 17';
    },

    /**
     * Verifica si hay adjuntos disponibles.
     *
     * @returns {boolean} Verdadero si hay adjuntos, falso si no.
     */
    hasAttachments() {
      return !!(this.attachments && this.attachments.length > 0);
    },
    /**
     * Verifica si tiene la transcripcion de la llamada
     */
    hasTranscripts() {
      if (this.inbox.pbx === 'cisco_call_manager') {
        return this.contentAttributes.summary;
      }

      return !!this.contentAttributes.transcriptDetail;
    },
    /**
     * Comprueba si el mensaje es una llamada perdida entrante.
     * @returns {boolean} True si es una llamada perdida entrante, false de lo contrario.
     */
    isMissedCallIncoming() {
      return (
        this.messageContentType === MESSAGE_CONTENT_TYPE.MISSED_CALL &&
        this.messageType === MESSAGE_TYPE.INCOMING
      );
    },

    /**
     * Comprueba si el mensaje es una llamada perdida saliente.
     * @returns {boolean} True si es una llamada perdida saliente, false de lo contrario.
     */
    isMissedCallOutgoing() {
      return (
        this.messageContentType === MESSAGE_CONTENT_TYPE.MISSED_CALL &&
        this.messageType === MESSAGE_TYPE.OUTGOING
      );
    },

    /**
     * Comprueba si el mensaje es una llamada contestada entrante.
     * @returns {boolean} True si es una llamada contestada entrante, false de lo contrario.
     */
    isAnsweredCallIncoming() {
      return (
        this.messageContentType === MESSAGE_CONTENT_TYPE.ANSWERED_CALL &&
        this.messageType === MESSAGE_TYPE.INCOMING
      );
    },

    /**
     * Comprueba si el mensaje es una llamada contestada saliente.
     * @returns {boolean} True si es una llamada contestada saliente, false de lo contrario.
     */
    isAnsweredCallOutgoing() {
      return (
        this.messageContentType === MESSAGE_CONTENT_TYPE.ANSWERED_CALL &&
        this.messageType === MESSAGE_TYPE.OUTGOING
      );
    },
    /**
     * Comprueba si el tipo de contenido del mensaje es una llamada en proceso de inicio.
     * @returns {boolean} - `true` si es una llamada en proceso de inicio, `false` en caso contrario.
     */
    isStartingCall() {
      return this.messageContentType === MESSAGE_CONTENT_TYPE.STARTING_CALL;
    },
    /**
     * Comprueba si el tipo de contenido del mensaje es una llamada con error.
     * @returns {boolean} - `true` si es una llamada con error, `false` en caso contrario.
     */
    isErrorCall() {
      return this.messageContentType === MESSAGE_CONTENT_TYPE.ERROR_CALL;
    },
    /**
     * Determina el color de relleno de la elipse en base al tipo y contenido del mensaje.
     * @type {string}
     */
    ellipseFill() {
      if (this.isMissedCallIncoming || this.isMissedCallOutgoing) {
        return ELLIPSE_COLORS.MISSED;
      }
      if (this.isAnsweredCallIncoming) {
        return ELLIPSE_COLORS.ANSWERED_INCOMING;
      }
      if (this.isAnsweredCallOutgoing) {
        return ELLIPSE_COLORS.ANSWERED_OUTGOING;
      }
      if (this.isStartingCall) {
        return ELLIPSE_COLORS.STARTING_CALL;
      }
      if (this.isErrorCall) {
        return ELLIPSE_COLORS.ERROR_CALL;
      }
      return '';
    },

    /**
     * Determina el ícono a mostrar en base al tipo y contenido del mensaje.
     * @type {string}
     */

    callIcon() {
      if (this.isMissedCallIncoming || this.isAnsweredCallIncoming) {
        return CALL_ICONS.INCOMING;
      }
      if (this.isMissedCallOutgoing || this.isAnsweredCallOutgoing) {
        return CALL_ICONS.OUTGOING;
      }
      if (this.isStartingCall) {
        return CALL_ICONS.STARTING;
      }
      if (this.isErrorCall) {
        return CALL_ICONS.ERROR;
      }
      return '';
    },

    /**
     * Determina la clase de ícono a aplicar en base al tipo y contenido del mensaje.
     * @type {string}
     */
    iconClass() {
      if (this.isMissedCallIncoming || this.isMissedCallOutgoing) {
        return CALL_ICON_CLASS.MISSED_CALL_ICON;
      }
      if (this.isAnsweredCallIncoming || this.isAnsweredCallOutgoing) {
        return CALL_ICON_CLASS.ANSWERED_CALL_ICON;
      }
      if (this.isStartingCall) {
        return CALL_ICON_CLASS.STARTING_CALL_ICON;
      }
      if (this.isErrorCall) {
        return CALL_ICON_CLASS.ERROR_CALL_ICON;
      }
      return '';
    },
  },
  mounted() {
    // Al montar el componente, establecemos el viewBox del SVG a "0 0 42 42"
    const svgElement = document.querySelector('.call-bubble-svg');
    svgElement.setAttribute('viewBox', '0 0 40 40');
  },
  methods: {
    /**
     * Abre el modal del transcript.
     * @function
     * @throws {Error} Lanza un error si no se puede abrir el modal.
     */
    openTranscriptPopup() {
      this.showTranscriptPopup = true;
    },

    /**
     * Oculta el modal del transcript y limpia las transcripciones almacenadas.
     * @function
     * @throws {Error} Lanza un error si no se puede ocultar el modal o si falla la limpieza de las transcripciones.
     */
    hideTranscriptPopup() {
      /**
       * Despacha una acción para borrar las transcripciones almacenadas en el estado global.
       * @function
       */
      this.$store.dispatch('clearTranscriptions');

      this.showTranscriptPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.call-bubble {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.is-call .call-bubble {
  .call-bubble-svg {
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
  }

  .header-section {
    display: flex;
    align-items: flex-start;
  }

  .missed-call-icon {
    color: #cc2639;
  }

  .answered-call-icon {
    color: var(--conversa2-blue-600-color);
  }

  .starting-call-icon {
    color: #ffffff;
  }

  .error-call-icon {
    color: #e94b26;
  }

  .event-details {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
  .event-summary-title {
    color: var(--conversa2-neutral-600-color);
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: var(--space-two);
    text-align: left;
    margin: 8px 0px 4px 0px;
  }
  .recording-title {
    color: var(--conversa2-neutral-600-color);
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: var(--space-two);
    text-align: left;
    margin: 4px 0px 4px 0px;
  }
  .event-summary {
    color: var(--conversa2-neutral-800-color);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: var(--space-two);
    font-size: var(--font-size-small);
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 4px;
  }
  .event-summary::first-letter {
    text-transform: uppercase;
  }

  .event-title {
    font-style: normal;
    font-size: 1.4rem;
    font-weight: var(--font-weight-bold);
    line-height: var(--space-two);
    letter-spacing: 0px;
    text-align: left;
    color: var(--conversa2-neutral-800-color);
    margin: 0;
  }
  .event-detail {
    color: var(--conversa2-neutral-800-color);
    font-style: normal;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
    line-height: var(--space-two);
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
  }

  .audio-section {
    .audio-element {
      width: 100%;
      height: 28px;
      border-radius: 20px;
      gap: 12px;
      background: linear-gradient(0deg, #e0e0e1, #e0e0e1),
        linear-gradient(0deg, #f3fafe, #f3fafe) !important;
      border: 1px solid #e0e0e1;
    }
  }
  .full-transcript-container {
    text-align: right;
  }

  .full-transcript-link {
    font-style: normal;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
    line-height: var(--space-two);
    text-align: right;
    display: inline;
    color: var(--conversa2-blue-600-color);
    text-decoration: underline;
  }
}
</style>
