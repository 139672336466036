<template>
  <div class="message-text__wrap">
    <letter class="text-content" :html="message" />
    <div v-if="url !== null">
      <a
        class="download clear link button small text-left"
        rel="noreferrer noopener nofollow"
        target="_blank"
        :href="url"
      >
        {{ url }}
      </a>
    </div>
    <div v-else>
      {{
        $t('CONVERSATION.NO_SUPPORTED_MESSAGE', {
          channel: channelTypeDescription,
        })
      }}
    </div>
  </div>
</template>

<script>
import Letter from 'vue-letter';
import { CHANNEL_TYPE, CHANNEL } from 'shared/constants/messages';

export default {
  components: { Letter },
  props: {
    message: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    channelType: {
      type: String,
      default: '',
    },
    hasInstagramStory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    channelTypeDescription() {
      if (this.channelType === CHANNEL_TYPE.FACEBOOK && !this.hasInstagramStory)
        return CHANNEL.FACEBOOK;
      if (this.channelType === CHANNEL_TYPE.WHATSAPP) return CHANNEL.WHATSAPP;
      if (this.hasInstagramStory) return CHANNEL.INSTAGRAM;

      return this.$t('CONVERSATION.UNIDENTIFIED_CHANNEL');
    },
  },
};
</script>
<style lang="scss">
.text-left {
  text-align: left;
}
.text-content {
  overflow: auto;

  ul,
  ol {
    padding-left: var(--space-two);
  }
  table {
    margin: 0;
    border: 0;

    td {
      margin: 0;
      border: 0;
    }

    tr {
      border-bottom: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-normal);
  }
}
</style>
