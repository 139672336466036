<template>
  <div class="custom-audio-wrapper">
    <audio
      :id="'audio-' + messageId"
      ref="audio"
      class="custom-audio-element"
      @error="onError"
      @timeupdate="updateProgress"
      @play="onPlay"
      @pause="onPause"
      @ended="onEnded"
    />
    <div class="playback-controls" style="padding: 0;">
      <button class="play-button" @click="togglePlayback">
        <fluent-icon
          :icon="playing ? 'pause' : 'play'"
          type="solid"
          class="play-icon"
          size="28"
          viewbox="0 0 32 32"
        />
      </button>
      <div class="timer">
        {{ currentTime !== '00:00' ? currentTime : totalDurationFormatted() }}
      </div>
      <vue-slider
        ref="slider"
        v-model="progress"
        :min="0"
        :max="duration"
        :process="true"
        :tooltip="false"
        :processStyle="{ backgroundColor: 'var(--conversa2-blue-600-color)' }"
        :railStyle="{ backgroundColor: 'var(--conversa2-neutral-300-color)' }"
        :dotStyle="{ backgroundColor: 'var(--conversa2-blue-600-color)' }"
        :dotSize="10"
        height="4"
        width="100%"
        @change="val => (progress = +val)"
        @callback="$event => seekToPosition($event)"
      />

      <div class="speed-container">
        <button class="speed-button" @click="changeReproductionRate">
          {{ `x${rateValues[ratePosition]}` }}
        </button>
      </div>
      <div class="options-menu">
        <button class="menu-button" @click="toggleOptionsMenu">
          <svg
            width="2"
            height="8"
            viewBox="0 0 2 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1" cy="7" r="1" fill="#27282b" />
            <circle cx="1" cy="4" r="1" fill="#27282b" />
            <circle cx="1" cy="1" r="1" fill="#27282b" />
          </svg>
        </button>
        <div v-if="showOptionsMenu" class="options-dropdown">
          <button class="download-button" @click="downloadAudio">
            {{ $t('CONVERSATION.CUSTOM_AUDIO.DOWNLOAD') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FluentIcon from 'shared/components/FluentIcon/Index.vue';
import vueSlider from 'vue-slider-component';

/**
 * Componente de reproductor de audio personalizado.
 * @component
 */
export default {
  components: { FluentIcon, vueSlider },
  props: {
    messageId: {
      type: String,
      default: '',
    },
    totalDuration: {
      type: Number,
      default: 0,
    },
    /**
     * Fuente URL del archivo de audio.
     * @type {String}
     * @required
     */
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      retries: 0,
      playing: false,
      showOptionsMenu: false,
      progress: 0,
      audio: null,
      currentTime: '00:00',
      audioLoaded: false,
      rateValues: [1, 1.5, 2],
      ratePosition: 0,
      duration: this.totalDuration,
    };
  },
  computed: {},
  watch: {
    progress(progress) {
      if (this.audio) {
        if (this.audio.paused) {
          const currentTime = Math.floor(progress);
          this.currentTime = `${this.formatTime(currentTime)}`;
        }

        const audioInfo = {
          id: `audio-${this.messageId}`,
          url: this.source,
          currentTime: progress,
          ratePosition: this.ratePosition,
        };
        this.$store.dispatch('updateAudioInPlaylist', audioInfo);
      }
    },
  },
  mounted() {
    this.onAudioMounted();
  },
  beforeDestroy() {
    this.cleanAudioComponent();
  },
  methods: {
    /**
     * Obtiene la duración total del contenido formateada en formato "mm:ss".
     * Devuelve una cadena vacía si la duración es NaN o menor o igual a cero.

     * @returns {string} La duración total formateada en "mm:ss".
     */
    totalDurationFormatted() {
      if (Number.isNaN(this.duration) || this.duration <= 0) {
        return ''; // Devuelve una cadena vacía si es NaN o menor o igual a cero
      }

      const minutos = Math.floor(this.duration / 60);
      const segundos = this.duration % 60;
      return `${minutos
        .toString()
        .padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
    },
    /**
     * Realiza limpieza previa a la destrucción del componente para liberar memoria.
     */
    cleanAudioComponent() {
      if (this.audio) {
        this.audio.pause();
        this.audio = null; // Liberar la referencia
        this.audioLoaded = false; // Restablece el estado de carga

        if (this.progress === 0 && this.ratePosition === 0) {
          this.$store.dispatch('deleteAudioInPlaylist', this.source);
        }
      }
    },
    // carga el audio en la etiqueta html <audio>
    loadAudio() {
      const audioUrlParts = this.source.split('/');
      const audioFileName = audioUrlParts[audioUrlParts.length - 1];
      // Comprueba si el audio ya está cargado
      this.audio = this.$refs.audio;
      this.audio.src = this.source; // Establece la fuente
      this.audio.load();
      this.audioLoaded = true;
      return audioFileName;
    },
    /**
     * Devuelve la informacion del audio si esta en la lista de reproduccion
     */
    audioInfoFromPlaylist() {
      return this.$store.getters.getAudioInfoFromPlaylist(this.source);
    },

    /**
     * Maneja los errores al cargar el audio.
     */
    onError() {
      let audio = this.$refs.audio;
      if (
        audio.error.code === audio.error.MEDIA_ERR_SRC_NOT_SUPPORTED &&
        audio.readyState === audio.HAVE_NOTHING &&
        audio.networkState === audio.NETWORK_NO_SOURCE
      ) {
        if (this.retries < 10) {
          let time = `?${new Date().getTime()}`;
          setTimeout(() => {
            audio.src = `${audio.src}${time}`;
            audio.load();
            this.retries += 1;
          }, 2500);
        }
      }
    },

    /**
     * Formatea el tiempo en segundos a formato "mm:ss".
     * @param {number} seconds - Segundos totales.
     * @returns {string} - Tiempo en formato "mm:ss".
     */
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${secs
        .toString()
        .padStart(2, '0')}`;
    },

    /**
     * Alterna la visualización del menú de opciones.
     */
    toggleOptionsMenu() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },

    /**
     * Descarga el archivo de audio actual.
     */
    downloadAudio() {
      if (this.audio) {
        let audioFileName;
        // Carga el audio al componente
        if (!this.audio.src) {
          audioFileName = this.loadAudio();
        } else {
          const audioUrlParts = this.audio.src.split('/');
          audioFileName = audioUrlParts[audioUrlParts.length - 1];
        }

        const link = document.createElement('a');
        link.href = this.audio.src;

        link.download = audioFileName;
        this.toggleOptionsMenu();
        link.click();
      }
    },

    /**
     * Actualiza el progreso de reproducción y tiempo actual.
     */
    updateProgress() {
      if (this.audio) {
        const currentTime = Math.floor(this.progress);
        this.currentTime = `${this.formatTime(currentTime)}`;
        if (this.audio.currentTime < this.duration) {
          this.progress = this.audio.currentTime;
        }
      }
    },

    /**
     * Maneja el evento de inicio de reproducción.
     */
    onPlay() {
      let audioPlaying = this.$store.getters.getAudioPlaying;
      if (audioPlaying !== null && audioPlaying !== `audio-${this.messageId}`) {
        let audio = document.querySelector(`#${audioPlaying}`);
        audio.pause();
      }

      this.playing = true;
      this.$store.dispatch('setAudioPlaying', `audio-${this.messageId}`);

      // si no hay informacion del audio en el playlist
      if (!this.audioInfoFromPlaylist()) {
        const audioInfo = {
          id: `audio-${this.messageId}`,
          url: this.source,
          currentTime: this.progress,
          ratePosition: this.ratePosition,
        };
        this.$store.dispatch('addAudioInPlaylist', audioInfo);
      }
    },

    /**
     * Maneja el evento de pausa de reproducción.
     */
    onPause() {
      this.playing = false;
      let audioPlaying = this.$store.getters.getAudioPlaying;
      if (audioPlaying === `audio-${this.messageId}`) {
        this.$store.dispatch('setAudioPlaying', null);
      }
    },
    /**
     * Maneja el evento de ended del reproductor
     */
    onEnded() {
      // Reinicia el valor de progress cuando se termina de reproducir el audio
      this.progress = 0;
    },
    /**
     * Alterna la reproducción del audio.
     * Si el audio no está cargado, carga la fuente antes de la reproducción.
     */
    togglePlayback() {
      if (!this.audioLoaded) {
        // Comprueba si el audio ya está cargado
        this.audio = this.$refs.audio;
        this.audio.src = this.source; // Establece la fuente
        this.audio.load();
        this.audio.currentTime = this.progress;
        this.audio.playbackRate = this.rateValues[this.ratePosition];
        this.audioLoaded = true; // Marca el audio como cargado
      }

      if (this.playing) {
        this.audio.pause();
      } else {
        this.audio.play();
      }
    },
    /**
     * Maneja el montaje del elemento de audio.
     */
    onAudioMounted() {
      if (!this.audioLoaded) {
        this.loadAudio();
        this.audio.currentTime = this.progress;
        this.audio.playbackRate = this.rateValues[this.ratePosition];
        this.audio.onloadedmetadata = event => {
          this.duration = Math.floor(event.target.duration);
        };
      }

      // carga la informacion de reproduccion del audio: velocidad de reproduccion y tiempo de reproduccion por donde se quedo
      if (this.audioInfoFromPlaylist()) {
        this.ratePosition = this.audioInfoFromPlaylist().ratePosition;
        this.progress = this.audioInfoFromPlaylist().currentTime;
      }
    },
    // registra la informacion del audio, si no existe en la lista de reproduccion lo inserta y si ya existe actualiza su información
    registerAudioInfo() {
      if (this.audioInfoFromPlaylist()) {
        const audioInfo = {
          id: `audio-${this.messageId}`,
          url: this.source,
          currentTime: this.progress,
          ratePosition: this.ratePosition,
        };
        this.$store.dispatch('updateAudioInPlaylist', audioInfo);
      } else {
        const audioInfo = {
          id: `audio-${this.messageId}`,
          url: this.source,
          currentTime: this.progress,
          ratePosition: this.ratePosition,
        };
        this.$store.dispatch('addAudioInPlaylist', audioInfo);
      }
    },
    // funcion que se ejecuta cuando el usuario cambia la velocidad de reproduccion el audio
    changeReproductionRate() {
      // avanza a la proxima posicion de donde tomará el valor de aceleracion para el audio
      const nextRateIndexValue =
        this.ratePosition + 1 < this.rateValues.length
          ? this.ratePosition + 1
          : 0;
      this.ratePosition = nextRateIndexValue;
      this.audio.playbackRate = this.rateValues[nextRateIndexValue];

      this.registerAudioInfo();
    },
    /**
     * Se desplaza a la posición deseada en el audio.
     * @param {Numebr} position - Indica el progreso del audio en el Slider.
     */
    seekToPosition(position) {
      if (this.audio) {
        const wasPlaying = !this.audio.paused;
        if (wasPlaying) {
          this.audio.pause();
        }

        this.audio.currentTime = position;

        if (wasPlaying) {
          this.audio.play();
        }
        this.registerAudioInfo();
      }
    },
  },
};
</script>

<style lang="scss">
.playback-controls {
  .vue-slider-component {
    .vue-slider-dot .vue-slider-dot-handle {
      background-color: var(--conversa2-blue-600-color);
    }
    .vue-slider {
      background-color: var(--conversa2-neutral-100-color);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables.scss';

.playback-controls {
  display: flex;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  height: 4rem;
  border-radius: 2rem;
  background: var(--white);
  border: 1px solid var(--conversa2-neutral-300-color);
  padding: 9px 12px;
  align-self: stretch;
}
.play-button {
  margin-left: 0.8rem;
}

.play-icon,
.pause-icon,
.volume-icon,
.options-icon {
  color: var(--conversa2-blue-600-color);
}

.volume-controls {
  display: flex;
  align-items: center;
}

.options-menu {
  position: relative;
}

.download-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 6px;
  text-align: left;
  color: $color-body;
}

.timer {
  text-align: center;
  min-width: 3rem;
  color: var(--conversa2-neutral-600-color);
  font-size: var(--font-size-mini);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  line-height: var(--space-normal);
  margin-right: 0.3rem;
}

.options-dropdown {
  position: absolute;
  top: 2.8rem;
  right: 0;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  z-index: 999;
}

.download-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 6px;
  text-align: left;
  color: $color-body;
  width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speed-container {
  margin-left: 0.3rem;
  margin-right: 0.8rem;
  height: var(--space-medium);
}

.speed-button {
  height: var(--space-medium);
  width: var(--space-large);
  border-radius: var(--space-one);
  background-color: var(--conversa2-neutral-50-color);
  text-align: center;
  color: var(--conversa2-neutral-800-color);
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-medium);
  line-height: var(--space-normal);
}

.progress-bar-container {
  cursor: pointer;
  width: 100%;
  height: 1rem;
  margin-top: 0px;
}

.progress-bar {
  width: 100%;
  height: 3px;
  background-color: var(--conversa2-neutral-300-color);
  border-radius: 1px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 3px;
}

.progress-bar-container:hover .progress {
  background-color: var(--conversa2-blue-600-color);
}

.progress {
  height: 100%;
  background-color: var(--conversa2-blue-600-color);
  transition: width 0.3s;
  position: absolute;
  top: 0;
  left: 0;
}

.play-button,
.menu-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
}

.menu-button {
  margin-right: 0.8rem;
  height: var(--space-medium);
  width: var(--space-medium);
  right: var(--space-small);
  background-color: var(--conversa2-neutral-50-color);
  border-radius: 50%;
}

.download-button:hover {
  color: $color-woot;
  transition: color 0.3s;
}

.custom-audio-wrapper {
  margin-bottom: 8px;
}
</style>
