<template>
  <div>
    <div v-if="typeFile === 'svg'" class="image message-text__wrap content-img-loader">
      <img
        :id="'svg-content-' + id"
        :src="isLoading ? imageUrl : url"
        :alt="'img' + id"
        class="image-loader-img"
        @click="onClick"
        @error="onImgError()"
        @load="onLoaded"
      />
    </div>
    <div v-else-if="typeFile === 'tiff'" class="image message-text__wrap">
      <div class="content-img-loader content-tiff" @click="onClick">
        <img
          :id="'tiff-content-canvas-' + id"
          :src="imageUrl"
          :alt="'img' + id"
          class="image-loader-img"
          @error="onImgError()"
          @load="onLoaded"
        />
      </div>
    </div>
    <div v-else-if="typeFile === 'gif'" class="image message-text__wrap content-img-loader" @click="onClick">
      <img
        :id="'gif-content-img-' + id"
        :src="imageUrl"
        :alt="'img' + id"
        class="image-loader-img"
        @error="onImgError()"
        @load="onLoaded"
      />
    </div>
    <div v-else>
      <download-file :url="url" />
    </div>
    <modal-view-file
      v-if="typeFile === 'svg' || typeFile === 'tiff' || typeFile === 'gif'"
      :id="id"
      :show="show"
      :on-close="onClose"
      :url="url"
      :type-file="typeFile"
    />
  </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
import Modal from './integrations/Modal.vue';
import {
  getBytesTiff,
  decodeImage,
  renderImage,
  getBytesImg,
  getBytesSVG
} from '../../../../helper/commons';
import DownloadFile from './integrations/Download.vue';

export default {
  components: {
    'inline-svg': InlineSvg,
    'modal-view-file': Modal,
    'download-file': DownloadFile,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      isLoading: true,
      imageUrl: 'https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif'
    };
  },
  computed: {
    typeFile() {
      return this.url
        .split(/[#?]/)[0]
        .split('.')
        .pop()
        .trim();
    },
  },
  async mounted() {
    this.addClassBubble();
    if (this.typeFile === 'tiff' && this.url) {
      const imageContent = 'tiff-content-canvas-' + this.id;
      await this.onGenerateTiff(this.url, imageContent);
    } else if (this.typeFile === 'gif') {
      const imageContent = `gif-content-img-${this.id}`;
      await this.onGenerateImage(imageContent);
    } else if (this.typeFile === 'svg') {
      const imageContent = `svg-content-${this.id}`;
      await this.onGenerateSVG(imageContent);
    }
  },
  methods: {
    onLoaded() {
      this.$emit('load');
    },
    onImgError() {
      this.$emit('error');
    },
    openLink() {
      const win = window.open(this.url, '_blank', 'noopener');
      if (win) win.focus();
    },
    addClassBubble() {
      const bubble = document.getElementById(`conversation-${this.id}`);
      if (
        this.typeFile === 'svg' ||
        this.typeFile === 'tiff' ||
        this.typeFile === 'gif'
      ) {
        bubble.className = 'bubble is-image';
      }
    },
    onClose() {
      this.show = false;
    },
    onClick() {
      if (this.typeFile === 'tiff') {
        const imageContent = 'tiff-content-canvas-modal' + this.id;
        this.onGenerateTiff(this.url, imageContent, '100%');
      } else if (this.typeFile === 'gif') {
        const imageContent = 'gif-content-modal-img-' + this.id;
        this.onGenerateImage(imageContent, true);
      }
      this.show = true;
    },
    async onGenerateTiff(url, imageContent, width = '32rem') {
      const bytes = await getBytesTiff(url);
      // Decodificar imagen
      const decode = decodeImage(bytes);
      // Render imagen
      renderImage(imageContent, decode, width);
    },
    async onGenerateImage(imageContent, isModal = false) {
      try {
        const newImage = await getBytesImg(this.url, this.id);
        const image = document.getElementById(imageContent);
        image.setAttribute('src', newImage);
        image.removeAttribute('class');
        if (!isModal) {
          const parent = image.parentElement;
          parent.setAttribute('class', 'image message-text__wrap content-tiff');
        }
      } catch (error) {
        console.error('[ERROR]', error);
      }
    },
    async onGenerateSVG(imageContent) {
      try {
        const image = document.getElementById(imageContent);
        const bytes = await getBytesSVG(this.url);
        const svgData = new Blob([bytes], { type: 'image/svg+xml' });
        const svgUrl = URL.createObjectURL(svgData);
        image.setAttribute('src', svgUrl);
        image.removeAttribute('class');
        const parent = image.parentElement;
        parent.setAttribute('class', 'image message-text__wrap');

      } catch (error) {
        console.error('[ERROR]', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.file {
  display: flex;
  flex-direction: row;
  padding: var(--space-small);
  cursor: pointer;
  gap: var(--space-one);

  .icon-wrap {
    font-size: $font-size-giga;
    color: $color-white;
    line-height: 1;
  }

  .text-block-title {
    margin: 0;
    color: $color-white;
    font-weight: $font-weight-bold;
    word-break: break-word;
  }

  .button {
    padding: 0;
    margin: 0;
    color: $color-primary-light;
  }

  .time {
    min-width: $space-larger;
  }
}
.modal-content-svg {
  width: 50%;
  height: 50%;
  .svg-content {
    width: 100%;
    height: 100%;
  }
}
.content-tiff {
  display: flex;
  justify-content: center;
}

.content-img-loader {
  display: flex;
  width: 32rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
}
.image-loader-img {
  width: 50px;
  height: 50px;
}
</style>
