import { MESSAGE_TYPE } from 'shared/constants/messages';
import { applyPageFilters } from './helpers';

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

// getters
const getters = {
  /**
   * Getter para obtener el tipo de respuesta de una conversación específica.
   * @param {object} _state - Estado actual del módulo Vuex.
   * @param {string} conversationId - ID de la conversación de la cual se desea obtener el tipo de respuesta.
   * @returns {string|null} Tipo de respuesta de la conversación especificada, o null si no se encuentra.
   */
  getConversationReplyType: _state => conversationId => {
    return _state.conversationReplyTypes[conversationId] || null;
  },

  getAllConversations: ({ allConversations }) =>
    allConversations.sort(
      (a, b) => b.messages.last()?.created_at - a.messages.last()?.created_at
    ),
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getLastEmailInSelectedChat: (stage, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    const { messages = [] } = selectedChat;
    const lastEmail = [...messages].reverse().find(message => {
      const {
        content_attributes: contentAttributes = {},
        message_type: messageType,
      } = message;
      const { email = {} } = contentAttributes;
      const isIncomingOrOutgoing =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.INCOMING;
      if (email.from && isIncomingOrOutgoing) {
        return true;
      }
      return false;
    });

    return lastEmail;
  },
  getMineChats: (_state, _, __, rootGetters) => activeFilters => {
    const currentUserID = rootGetters.getCurrentUser?.id;

    return _state.allConversations.filter(conversation => {
      const { assignee } = conversation.meta;
      const isAssignedToMe = assignee && assignee.id === currentUserID;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isChatMine = isAssignedToMe && shouldFilter;

      return isChatMine;
    });
  },
  getAppliedConversationFilters: _state => {
    return _state.appliedFilters;
  },
  getUnAssignedChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const isUnAssigned = !conversation.meta.assignee;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      return isUnAssigned && shouldFilter;
    });
  },
  getAllStatusChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      return shouldFilter;
    });
  },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
  getUnreadCount(_state) {
    const [chat] = getSelectedChatConversation(_state);
    if (!chat) return [];
    return chat.messages.filter(
      chatMessage =>
        chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
        chatMessage.message_type === 0 &&
        chatMessage.private !== true
    ).length;
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
  getConversationById: _state => conversationId => {
    return _state.allConversations.find(
      value => value.id === Number(conversationId)
    );
  },
  getConversationParticipants: _state => {
    return _state.conversationParticipants;
  },
  getConversationLastSeen: _state => {
    return _state.conversationLastSeen;
  },
  /**
   * Obtiene las transcripciones almacenadas en el estado Vuex.
   * @function getTranscriptions
   * @param {object} _state - El estado Vuex.
   * @returns {array} Un array de transcripciones.
   */
  getTranscriptions: _state => {
    return _state.transcriptions;
  },

  /**
   * Obtiene el ID del audio que se esta reproduciendo
   */
  getAudioPlaying: _state => {
    return _state.audioPlaying;
  },
  /**
   * Obtiene la informacion de un audio que este en la lista de reproduccion
   */
  getAudioInfoFromPlaylist: _state => audioURL => {
    return _state.playlist.find(audio => audio.url === audioURL);
  },
  /**
   * Obtiene un borrador de mensaje específico del estado.
   *
   * @param {Object} state - El estado del módulo de conversaciones.
   * @returns {Function} Una función que recibe `conversationId` y `replyType` y devuelve el borrador correspondiente.
   */
  getDraft: _state => (conversationId, replyType) => {
    const key = `draft-${conversationId}-${replyType}`;
    return _state.draftMessages[key];
  },
};

export default getters;
