/* eslint-disable no-console */
import NotificationSubscriptions from '../api/notificationSubscription';
import auth from '../api/auth';

export const verifyServiceWorkerExistence = (callback = () => {}) => {
  if (!('serviceWorker' in navigator)) {
    // Service Worker isn't supported on this browser, disable or hide UI.
    return;
  }

  if (!('PushManager' in window)) {
    // Push isn't supported on this browser, disable or hide UI.
    return;
  }

  navigator.serviceWorker
    .register('/sw.js')
    .then(registration => callback(registration))
    .catch(registrationError => {
      // eslint-disable-next-line
      console.log('SW registration failed: ', registrationError);
    });
};

export const hasPushPermissions = () => {
  if ('Notification' in window) {
    return Notification.permission === 'granted';
  }
  return false;
};

const generateKeys = str =>
  btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

export const getPushSubscriptionPayload = subscription => ({
  subscription_type: 'browser_push',
  subscription_attributes: {
    endpoint: subscription.endpoint,
    p256dh: generateKeys(subscription.getKey('p256dh')),
    auth: generateKeys(subscription.getKey('auth')),
  },
});

export const sendRegistrationToServer = subscription => {
  if (auth.hasAuthCookie()) {
    return NotificationSubscriptions.create(
      getPushSubscriptionPayload(subscription)
    );
  }
  return null;
};


// Constante para el mensaje de error por default de las notificaciones de escritorio.
const defaultMessageError = 'Este navegador no admite notificaciones de escritorio'

/**
 * Registra una suscripción para recibir notificaciones push en el navegador.
 * @param {Object} i18n - Objeto de internacionalización. Opcional.
 * @param {Function} onSuccess - Función a ejecutar si la suscripción se realiza con éxito. Opcional.
 */
export const registerSubscription = (i18n = null, onSuccess = () => {}) => {
  if (!window.chatwootConfig.vapidPublicKey) {
    return;
  }
  navigator.serviceWorker.ready
    .then(serviceWorkerRegistration =>
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.chatwootConfig.vapidPublicKey,
      })
    )
    .then(sendRegistrationToServer)
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      window.bus.$emit(
        'newToastMessage',
        i18n ? i18n.t('NOTIFICATIONS_PAGE.ERRORS.BROWSER_NOT_SUPPORTED') : defaultMessageError
      );
    });
};

/**
 * Solicita permiso al usuario para recibir notificaciones push en el navegador.
 * @param {Object} options - Opciones para la función.
 * @param {Function} options.onSuccess - Función a ejecutar si el usuario concede permiso. Obligatorio.
 * @param {Object} [options.i18n=null] - Objeto de internacionalización. Opcional.
 */
export const requestPushPermissions = ({ onSuccess, i18n = null }) => {
  if (!('Notification' in window)) {
    window.bus.$emit(
      'newToastMessage',
      i18n ? i18n.t('NOTIFICATIONS_PAGE.ERRORS.BROWSER_NOT_SUPPORTED') : defaultMessageError
      );
  } else if (Notification.permission === 'granted') {
    registerSubscription(onSuccess);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission(permission => {
      if (permission === 'granted') {
        registerSubscription(onSuccess);
      }
    });
  }
};
