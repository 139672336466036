<template>
  <div>
    <woot-button
      :icon="isRightOrLeftIcon"
      size="tiny"
      variant="smooth"
      color-scheme="secondary"
      @click="toggle"
    />
  </div>
</template>

<script>
export default {
  props: {
    isOnExpandedLayout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Determina el ícono de la flecha que debe mostrarse según el layout actual.
     *
     * Este método devuelve el nombre del ícono de flecha que se debe mostrar basado en el estado de
     * `isOnExpandedLayout`. Si la interfaz está en un layout expandido, devuelve `'arrow-chevron-right'`.
     * De lo contrario, devuelve `'arrow-chevron-left'`.
     *
     * @method isRightOrLeftIcon
     * @returns {string} Devuelve `'arrow-chevron-right'` si la interfaz está en el layout expandido,
     *                   o `'arrow-chevron-left'` si no lo está.
     */
    isRightOrLeftIcon() {
      if (this.isOnExpandedLayout) {
        return 'arrow-chevron-right';
      }
      return 'arrow-chevron-left';
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    },
  },
};
</script>
