<template>
  <div class="assignee-label">
    <div v-if="nameAgent" class="mt-5">
      <fluent-icon
        icon="conversa2-person"
        type="outline"
        viewbox="0 0 12 12"
        size="12"
      />
    </div>
    <p v-if="nameAgent" class="text-truncate label">
      {{ nameAgent }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    nameAgent: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  line-height: var(--space-normal);
  font-weight: var(--font-weight-normal);
  background: none;
  font-size: var(--font-size-mini);
  padding: 0;
  margin: 0;
  color: var(--conversa2-blue-600-color);
}

.mt-5 {
  margin-top: var(--space-smaller);
}

.assignee-label {
  max-width: calc((100% / 3) * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 16px;
  color: var(--conversa2-blue-600-color);
}
</style>
