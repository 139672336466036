<template>
  <label class="input-container">
    <span v-if="label">{{ label }}</span>
    <input
      v-model="internalValue"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :style="styles"
      :class="{
        'error-border': shouldShowError && (isCharLimitExceeded || isCharMin),
      }"
      @input="onChange"
    />
    <div
      class="char-counter"
      :class="{
        'error-text': shouldShowError && (isCharLimitExceeded || isCharMin),
      }"
    >
      <span v-if="isCharLimitExceeded" class="char-limit-message">
        {{ getCharLimitMessage }}
      </span>
      <span v-if="isCharMin" class="char-limit-message">
        {{ getCharMinMessage }}
      </span>
      <div
        :class="{
          'char-count': true,
          'error-text': shouldShowError && (isCharLimitExceeded || isCharMin),
        }"
      >
        {{ charCount }} / 30
      </div>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      internalValue: this.value,
      charCount: this.value.length,
      shouldShowError: false,
    };
  },
  computed: {
    isCharLimitExceeded() {
      return this.charCount > 30;
    },
    isCharMin() {
      return this.charCount === 0;
    },
    getCharLimitMessage() {
      return this.isCharLimitExceeded && this.shouldShowError
        ? this.$t('INBOX_MGMT.CHANNEL_NAME.CHARACTER_MAX_LIMIT')
        : '';
    },
    getCharMinMessage() {
      return this.isCharMin && this.shouldShowError
        ? this.$t('INBOX_MGMT.CHANNEL_NAME.CHARACTER_MIN_LIMIT')
        : '';
    },
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
      this.charCount = newVal.length;
    },
  },
  methods: {
    /**
     * Maneja el evento de cambio de entrada.
     * Emite el evento 'input' con el valor interno actualizado.
     * Actualiza charCount y muestra un mensaje de error si es necesario.
     */
    onChange() {
      this.$emit('input', this.internalValue);
      this.charCount = this.internalValue.length;

      // Mostrar mensaje de error inmediatamente si se eliminan todos los caracteres
      this.shouldShowError = this.isCharMin || this.isCharLimitExceeded;
    },
  },
};
</script>

<style scoped lang="scss">
.input-container input {
  margin: 0;
}

.input-container {
}

.message {
  margin-top: 0 !important;
}

.char-counter {
  margin-top: var(--space-smaller);
  font-size: 12px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 16px;

  &.error-text {
    color: var(--conversa2-red-600-color, #e63543);
  }
}

.char-count {
  margin-left: auto;
}
.char-count.error-text {
  color: var(--conversa2-red-600-color, #e63543);
}

.char-limit-message {
  margin-right: var(--space-mini);
  color: var(--conversa2-red-600-color, #e63543);
}

.input-container input {
  margin: 0;
  &.error-border {
    border: 1px solid var(--conversa2-red-600-color, #e63543);
  }
}
</style>
