<template>
  <woot-modal
    :show="show"
    :on-close="() => {
      $emit('close-account-create-modal');
      logout();
    }"
    class="account-selector--modal"
  >
    <div class="column content-box">
      <woot-modal-header/>
      <div v-if="!hasAccounts" class="alert-wrap">
        <div class="callout alert">
          <div class="icon-wrap">
            <fluent-icon icon="warning" />
          </div>
          {{ $t('CREATE_ACCOUNT.NO_ACCOUNT_WARNING') }}
        </div>
      </div>
      <form class="row" @submit.prevent="logout">
        <div class="modal-footer medium-12 columns">
          <div class="medium-12 columns">
            <woot-submit-button
              :button-text="$t('CREATE_ACCOUNT.FORM.SUBMIT')"
              button-class="large expanded"
            />
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import Auth from '../../../api/auth';

export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hasAccounts: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      accountName: '',
    };
  },
  validations: {
    accountName: {
      required,
      minLength: minLength(1),
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
    }),
  },
  methods: {
    /**
      Método que se encarga de cerrar la sesión del usuario.
      Llama al método logout() de la clase Auth para manejar la desconexión.
      @returns {void}
    */
    logout() {
      Auth.logout();
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-wrap {
  font-size: var(--font-size-small);
  margin: var(--space-medium) var(--space-large) var(--space-zero);

  .callout {
    align-items: center;
    border-radius: var(--border-radius-normal);
    display: flex;
  }
}

.icon-wrap {
  margin-left: var(--space-smaller);
  margin-right: var(--space-slab);
}
</style>
