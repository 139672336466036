<template>
  <input
    class="icp__input2"
    type="color"
    :style="`background-color: ${color}`"
    :value="color"
    @input="handleInput"
    @change="handleChange"
  />
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    input: {
      type: Function,
      required: true,
    },
    change: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isPickerOpen: false,
    };
  },
  methods: {
    handleInput(e) {
      this.input(e.target.value);
      this.$emit('input', e.target.value);
    },
    handleChange(e) {
      this.change(e.target.value);
      this.$emit('change', e.target.value);
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.icp__input2 {
  height: $space-large;
  width: $space-large;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
}

.icp__input2::-webkit-color-swatch-wrapper {
  padding: 0;
}

.icp__input2::-webkit-color-swatch {
  border: none;
  border-radius: 15px;
}

.icp__input2::-moz-color-swatch {
  border: none;
  border-radius: 15px;
}
</style>
