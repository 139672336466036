/* global axios */
import ApiClient from './ApiClient';

/**
 * Construye los parámetros de consulta para las solicitudes de agentes.
 *
 * @param {number} page - Número de página para la paginación.
 * @param {string} sortAttr - Atributo por el cual se ordenan los resultados.
 * @param {string} label - Etiqueta opcional para el ordenamiento.
 * @param {string} search - Término de búsqueda opcional.
 * @returns {string} - Parámetros de consulta formateados como una cadena.
 */
export const buildAgentParams = (page, sortAttr, label, search) => {
  let params = `page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&q=${search}`;
  }
  return params;
};

class AgentAPI extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  /**
   * Realiza una solicitud GET para recuperar la lista de todos los agentes.
   *
   * @returns {Promise} - Promesa que se resuelve con la respuesta de la solicitud.
   */
  get() {
    return axios.get(this.url);
  }

  /**
   * Realiza una solicitud GET para recuperar la lista de agentes paginados.
   *
   * @param {number} page - Número de página para la paginación.
   * @param {string} sortAttr - Atributo por el cual se ordenan los resultados.
   * @param {string} label - Etiqueta opcional para el ordenamiento.
   * @returns {Promise} - Promesa que se resuelve con la respuesta de la solicitud.
   */
  getPaginated(page, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}/paginated?${buildAgentParams(
      page,
      sortAttr,
      label,
      ''
    )}`;
    return axios.get(requestURL);
  }

  /**
   * Realiza una solicitud GET para buscar agentes según un término de búsqueda.
   *
   * @param {string} search - Término de búsqueda.
   * @param {number} page - Número de página para la paginación.
   * @param {string} sortAttr - Atributo por el cual se ordenan los resultados.
   * @param {string} label - Etiqueta opcional para el ordenamiento.
   * @returns {Promise} - Promesa que se resuelve con la respuesta de la solicitud.
   */
  search(search = '', page = 1, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}/search?${buildAgentParams(
      page,
      sortAttr,
      label,
      search
    )}`;
    return axios.get(requestURL);
  }

  /**
   * Realiza una solicitud POST para filtrar agentes según criterios específicos.
   *
   * @param {number} page - Número de página para la paginación.
   * @param {string} sortAttr - Atributo por el cual se ordenan los resultados.
   * @param {Object} queryPayload - Criterios de filtro en formato de objeto.
   * @returns {Promise} - Promesa que se resuelve con la respuesta de la solicitud.
   */
  filter(page = 1, sortAttr = 'name', queryPayload) {
    let requestURL = `${this.url}/filter?${buildAgentParams(page, sortAttr)}`;
    return axios.post(requestURL, queryPayload);
  }
}

export default new AgentAPI();
