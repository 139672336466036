<template>
  <section class="conversation-page">
    <chat-list
      :show-conversation-list="showConversationList"
      :conversation-inbox="inboxId"
      :label="label"
      :team-id="teamId"
      :conversation-type="conversationType"
      :folders-id="foldersId"
      :is-on-expanded-layout="isOnExpandedLayout"
      @conversation-load="onConversationLoad"
    >
      <pop-over-search
        :is-on-expanded-layout="isOnExpandedLayout"
        @toggle-conversation-layout="toggleConversationLayout"
      />
    </chat-list>
    <conversation-box
      :inbox-id="inboxId"
      :is-contact-panel-open="isContactPanelOpen"
      :is-on-expanded-layout="isOnExpandedLayout"
      @contact-panel-toggle="onToggleContactPanel"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatList from '../../../components/ChatList';
import ConversationBox from '../../../components/widgets/conversation/ConversationBox';
import PopOverSearch from './search/PopOverSearch';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  components: {
    ChatList,
    ConversationBox,
    PopOverSearch,
  },
  mixins: [uiSettingsMixin],
  props: {
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    teamId: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      showSearchModal: false,
    };
  },
  computed: {
    ...mapGetters({
      chatList: 'getAllConversations',
      currentChat: 'getSelectedChat',
    }),
    /**
     * Determina si se debe mostrar la lista de conversaciones.
     *
     * Este método devuelve un valor booleano basado en el estado de la propiedad `isOnExpandedLayout`.
     * Si `isOnExpandedLayout` es verdadero, el método devolverá `true`, lo que indica que la lista
     * de conversaciones debe mostrarse. Si es falso, devolverá `false`.
     *
     * @method showConversationList
     * @returns {boolean} Devuelve `true` si la lista de conversaciones debe mostrarse, de lo contrario, `false`.
     */
    showConversationList() {
      return !!this.isOnExpandedLayout;
    },
    showMessageView() {
      return this.conversationId ? true : !this.isOnExpandedLayout;
    },
    /**
     * Verifica si la interfaz está en el modo de layout expandido.
     *
     * Este método evalúa el estado de la configuración `show_chatlist` en `uiSettings`.
     * Si `show_chatlist` no es `true`, el método devolverá `true`, indicando que la interfaz
     * está en un layout expandido. De lo contrario, devolverá `false`.
     *
     * @method isOnExpandedLayout
     * @returns {boolean} Devuelve `true` si la interfaz está en el layout expandido, de lo contrario, `false`.
     */
    isOnExpandedLayout() {
      const { show_chatlist: showChatList = true } = this.uiSettings;
      return showChatList !== true;
    },
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const {
          is_contact_sidebar_open: isContactSidebarOpen,
        } = this.uiSettings;
        return isContactSidebarOpen;
      }
      return false;
    },
  },
  watch: {
    conversationId() {
      this.fetchConversationIfUnavailable();
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.initialize();
    this.$watch('$store.state.route', () => this.initialize());
    this.$watch('chatList.length', () => {
      this.setActiveChat();
    });
  },

  methods: {
    onConversationLoad() {
      this.fetchConversationIfUnavailable();
    },
    initialize() {
      this.$store.dispatch('setActiveInbox', this.inboxId);
      this.setActiveChat();
    },
    /**
     * Alterna la visibilidad de la lista de conversaciones en la interfaz de usuario.
     *
     * Este método obtiene el estado actual de la configuración `show_chatlist` desde `uiSettings`.
     * Si `show_chatlist` es verdadero, lo cambia a falso, y viceversa. Luego guarda el estado actualizado
     * en la configuración de la interfaz de usuario.
     *
     * @method toggleConversationLayout
     * @returns {void} No devuelve ningún valor.
     */
    toggleConversationLayout() {
      const { show_chatlist: showChatList = true } = this.uiSettings;
      const newShowChatList = !showChatList;
      this.updateUISettings({
        show_chatlist: newShowChatList,
      });
    },
    fetchConversationIfUnavailable() {
      if (!this.conversationId) {
        return;
      }
      const chat = this.findConversation();
      if (!chat) {
        this.$store.dispatch('getConversation', this.conversationId);
      }
    },
    findConversation() {
      const conversationId = parseInt(this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    setActiveChat() {
      if (this.conversationId) {
        const selectedConversation = this.findConversation();
        // If conversation doesn't exist or selected conversation is same as the active
        // conversation, don't set active conversation.
        if (
          !selectedConversation ||
          selectedConversation.id === this.currentChat.id
        ) {
          return;
        }
        this.$store.dispatch('setActiveChat', selectedConversation).then(() => {
          bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
        });
      } else {
        this.$store.dispatch('clearSelectedState');
      }
    },
    onToggleContactPanel() {
      this.updateUISettings({
        is_contact_sidebar_open: !this.isContactPanelOpen,
      });
    },
    onSearch() {
      this.showSearchModal = true;
    },
    closeSearch() {
      this.showSearchModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation-page {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
