import {
  OPERATOR_TYPES_1,
  OPERATOR_TYPES_2,
  OPERATOR_TYPES_3,
} from './operators';

/**
 * La función devuelve una matriz de objetos que representan diferentes condiciones para la automatización, con sus respectivas claves, nombres, tipos de entrada y operadores de filtro.
 */
const ConditionsDto = translate => [
  {
    key: 'status',
    name: translate.t('AUTOMATION.CONDITIONS.STATUS'),
    attributeI18nKey: 'STATUS',
    inputType: 'multi_select',
    filterOperators: OPERATOR_TYPES_1,
  },
  {
    key: 'browser_language',
    name: translate.t('AUTOMATION.CONDITIONS.BROWSER_LANGUAGE'),
    attributeI18nKey: 'BROWSER_LANGUAGE',
    inputType: 'search_select',
    filterOperators: OPERATOR_TYPES_1,
  },
  {
    key: 'mail_subject',
    name: translate.t('AUTOMATION.CONDITIONS.EMAIL_SUBJECT'),
    attributeI18nKey: 'MAIL_SUBJECT',
    inputType: 'plain_text',
    filterOperators: OPERATOR_TYPES_2,
  },
  {
    key: 'country_code',
    name: translate.t('AUTOMATION.CONDITIONS.COUNTRY'),
    attributeI18nKey: 'COUNTRY_NAME',
    inputType: 'search_select',
    filterOperators: OPERATOR_TYPES_1,
  },
  {
    key: 'referer',
    name: translate.t('AUTOMATION.CONDITIONS.REFERRER_LINK'),
    attributeI18nKey: 'REFERER_LINK',
    inputType: 'plain_text',
    filterOperators: OPERATOR_TYPES_2,
  },
  {
    key: 'team_id',
    name: translate.t('AUTOMATION.CONDITIONS.TEAM'),
    attributeI18nKey: 'TEAM_NAME',
    inputType: 'search_select',
    filterOperators: OPERATOR_TYPES_3,
  },
  {
    key: 'inbox_id',
    name: translate.t('AUTOMATION.CONDITIONS.INBOX'),
    attributeI18nKey: 'INBOX',
    inputType: 'multi_select',
    filterOperators: OPERATOR_TYPES_1,
  },
];

/**
 * La función inserta elementos en una matriz en un índice especificado.
 * @param array - El parámetro de matriz es la matriz en la que se insertarán los elementos.
 * @param index - El parámetro de índice es la posición en la matriz donde se insertarán los elementos.
 * @param elementsArray - El parámetro `elementsArray` es un parámetro de descanso que permite que la
 * función aceptar cualquier número de argumentos después del parámetro `index`. Estos argumentos se
 * recogerán en una matriz llamada `elementsArray`.
 * @returns La función `insertAt` está devolviendo el `arreglo` modificado después de insertar el
 * `elementsArray` en el `index` especificado.
 */
function insertAt(array, index, ...elementsArray) {
  array.splice(index, 0, ...elementsArray);
  return array;
}

/**
 * Esta función elimina un elemento de una matriz de objetos en función de un valor clave coincidente.
 * @param array - una matriz de objetos
 * @param elementsArray - `elementsArray` es un objeto con una propiedad `clave`. El
 * la función `removeAt` usa esta propiedad `key` para encontrar el índice del elemento en la `matriz`
 * parámetro que debe eliminarse.
 * @returns una nueva matriz con el elemento que tiene la misma clave que el elemento en `elementsArray`
 * parámetro eliminado del parámetro `array` original.
 */
function removeAt(array, elementsArray) {
  const findIndex = array.findIndex(a => a.key === elementsArray.key);
  return [...array.slice(0, findIndex), ...array.slice(findIndex + 1)];
}

/**
 * Esta es una función de JavaScript que define diferentes automatizaciones con sus respectivas condiciones
 * y acciones.
 * @param translate - El parámetro `translate` es una función que toma una cadena como entrada y devuelve
 * una versión traducida de esa cadena. Se utiliza para traducir los nombres de condiciones y acciones en
 * el objeto `AUTOMATIZACIONES`.
 */
export const AUTOMATIONS = translate => {
  const conditions = ConditionsDto(translate);
  return {
    message_created: {
      conditions: [
        {
          key: 'message_type',
          name: translate.t('AUTOMATION.CONDITIONS.MESSAGE_TYPE'),
          attributeI18nKey: 'MESSAGE_TYPE',
          inputType: 'search_select',
          filterOperators: OPERATOR_TYPES_1,
        },
        {
          key: 'content',
          name: translate.t('AUTOMATION.CONDITIONS.MESSAGE_CONTENT'),
          attributeI18nKey: 'MESSAGE_CONTAINS',
          inputType: 'plain_text',
          filterOperators: OPERATOR_TYPES_2,
        },
        {
          key: 'inbox_id',
          name: translate.t('AUTOMATION.CONDITIONS.INBOX'),
          attributeI18nKey: 'INBOX',
          inputType: 'multi_select',
          filterOperators: OPERATOR_TYPES_1,
        },
      ],
      actions: [
        {
          key: 'assign_agent',
          name: 'Assign to agent',
          attributeI18nKey: 'ASSIGN_AGENT',
        },
        {
          key: 'assign_team',
          name: 'Assign a team',
          attributeI18nKey: 'ASSIGN_TEAM',
        },
        {
          key: 'add_label',
          name: 'Add a label',
          attributeI18nKey: 'ADD_LABEL',
        },
        {
          key: 'send_email_to_team',
          name: 'Send an email to team',
          attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
        },
        {
          key: 'send_message',
          name: 'Send a message',
          attributeI18nKey: 'SEND_MESSAGE',
        },
        {
          key: 'send_email_transcript',
          name: 'Send an email transcript',
          attributeI18nKey: 'SEND_EMAIL_TRANSCRIPT',
        },
        {
          key: 'mute_conversation',
          name: 'Mute conversation',
          attributeI18nKey: 'MUTE_CONVERSATION',
        },
        {
          key: 'snooze_conversation',
          name: 'Snooze conversation',
          attributeI18nKey: 'MUTE_CONVERSATION',
        },
        {
          key: 'resolve_conversation',
          name: 'Resolve conversation',
          attributeI18nKey: 'RESOLVE_CONVERSATION',
        },
        {
          key: 'send_webhook_event',
          name: 'Send Webhook Event',
          attributeI18nKey: 'SEND_WEBHOOK_EVENT',
        },
        {
          key: 'send_attachment',
          name: 'Send Attachment',
          attributeI18nKey: 'SEND_ATTACHMENT',
        },
      ],
    },
    conversation_created: {
      conditions: removeAt(conditions, {
        key: 'team_id',
        name: translate.t('AUTOMATION.CONDITIONS.TEAM'),
        attributeI18nKey: 'TEAM_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      }),
      actions: [
        {
          key: 'assign_agent',
          name: 'Assign to agent',
          attributeI18nKey: 'ASSIGN_AGENT',
        },
        {
          key: 'assign_team',
          name: 'Assign a team',
          attributeI18nKey: 'ASSIGN_TEAM',
        },
        {
          key: 'assign_agent',
          name: 'Assign an agent',
          attributeI18nKey: 'ASSIGN_AGENT',
        },
        {
          key: 'send_email_to_team',
          name: 'Send an email to team',
          attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
        },
        {
          key: 'send_message',
          name: 'Send a message',
          attributeI18nKey: 'SEND_MESSAGE',
        },
        {
          key: 'send_email_transcript',
          name: 'Send an email transcript',
          attributeI18nKey: 'SEND_EMAIL_TRANSCRIPT',
        },
        {
          key: 'mute_conversation',
          name: 'Mute conversation',
          attributeI18nKey: 'MUTE_CONVERSATION',
        },
        {
          key: 'snooze_conversation',
          name: 'Snooze conversation',
          attributeI18nKey: 'MUTE_CONVERSATION',
        },
        {
          key: 'resolve_conversation',
          name: 'Resolve conversation',
          attributeI18nKey: 'RESOLVE_CONVERSATION',
        },
        {
          key: 'send_webhook_event',
          name: 'Send Webhook Event',
          attributeI18nKey: 'SEND_WEBHOOK_EVENT',
        },
        {
          key: 'send_attachment',
          name: 'Send Attachment',
          attributeI18nKey: 'SEND_ATTACHMENT',
        },
      ],
    },
    conversation_updated: {
      conditions: insertAt(conditions, 5, {
        key: 'assignee_id',
        name: translate.t('AUTOMATION.CONDITIONS.ASSIGNE'),
        attributeI18nKey: 'ASSIGNEE_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      }),
      actions: [
        {
          key: 'assign_agent',
          name: 'Assign to agent',
          attributeI18nKey: 'ASSIGN_AGENT',
        },
        {
          key: 'assign_team',
          name: 'Assign a team',
          attributeI18nKey: 'ASSIGN_TEAM',
        },
        {
          key: 'assign_agent',
          name: 'Assign an agent',
          attributeI18nKey: 'ASSIGN_AGENT',
        },
        {
          key: 'send_email_to_team',
          name: 'Send an email to team',
          attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
        },
        {
          key: 'send_message',
          name: 'Send a message',
          attributeI18nKey: 'SEND_MESSAGE',
        },
        {
          key: 'send_email_transcript',
          name: 'Send an email transcript',
          attributeI18nKey: 'SEND_EMAIL_TRANSCRIPT',
        },
        {
          key: 'mute_conversation',
          name: 'Mute conversation',
          attributeI18nKey: 'MUTE_CONVERSATION',
        },
        {
          key: 'snooze_conversation',
          name: 'Snooze conversation',
          attributeI18nKey: 'MUTE_CONVERSATION',
        },
        {
          key: 'resolve_conversation',
          name: 'Resolve conversation',
          attributeI18nKey: 'RESOLVE_CONVERSATION',
        },
        {
          key: 'send_webhook_event',
          name: 'Send Webhook Event',
          attributeI18nKey: 'SEND_WEBHOOK_EVENT',
        },
        {
          key: 'send_attachment',
          name: 'Send Attachment',
          attributeI18nKey: 'SEND_ATTACHMENT',
        },
      ],
    },
    conversation_opened: {
      conditions: removeAt(conditions, {
        key: 'status',
        name: translate.t('AUTOMATION.CONDITIONS.STATUS'),
        attributeI18nKey: 'STATUS',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      }),
      actions: [
        {
          key: 'assign_agent',
          name: 'Assign to agent',
          attributeI18nKey: 'ASSIGN_AGENT',
        },
        {
          key: 'assign_team',
          name: 'Assign a team',
          attributeI18nKey: 'ASSIGN_TEAM',
        },
        {
          key: 'assign_agent',
          name: 'Assign an agent',
          attributeI18nKey: 'ASSIGN_AGENT',
        },
        {
          key: 'send_email_to_team',
          name: 'Send an email to team',
          attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
        },
        {
          key: 'send_message',
          name: 'Send a message',
          attributeI18nKey: 'SEND_MESSAGE',
        },
        {
          key: 'send_email_transcript',
          name: 'Send an email transcript',
          attributeI18nKey: 'SEND_EMAIL_TRANSCRIPT',
        },
        {
          key: 'mute_conversation',
          name: 'Mute conversation',
          attributeI18nKey: 'MUTE_CONVERSATION',
        },
        {
          key: 'snooze_conversation',
          name: 'Snooze conversation',
          attributeI18nKey: 'MUTE_CONVERSATION',
        },
        {
          key: 'send_webhook_event',
          name: 'Send Webhook Event',
          attributeI18nKey: 'SEND_WEBHOOK_EVENT',
        },
        {
          key: 'send_attachment',
          name: 'Send Attachment',
          attributeI18nKey: 'SEND_ATTACHMENT',
        },
      ],
    },
  };
};

/**
 * La función devuelve una matriz de objetos que contienen claves y valores para diferentes reglas de automatización
 * eventos, traducidos según el idioma proporcionado.
 * @param translate - Es una función que toma una cadena como entrada y devuelve una versión traducida de
 * esa cadena basada en la configuración de idioma actual. Se utiliza para traducir los valores de la
 * Propiedad `value` en cada objeto de la matriz.
 */
export const AUTOMATION_RULE_EVENTS = translate => [
  {
    key: 'conversation_created',
    value: translate.t(
      'AUTOMATION.AUTOMATION_RULE_EVENTS.CONVERSATION_CREATED'
    ),
  },
  {
    key: 'conversation_updated',
    value: translate.t(
      'AUTOMATION.AUTOMATION_RULE_EVENTS.CONVERSATION_UPDATED'
    ),
  },
  {
    key: 'message_created',
    value: translate.t('AUTOMATION.AUTOMATION_RULE_EVENTS.MESSAGE_CREATED'),
  },
  {
    key: 'conversation_opened',
    value: translate.t('AUTOMATION.AUTOMATION_RULE_EVENTS.CONVERSATION_OPENED'),
  },
];

/**
 * La función devuelve una matriz de objetos que representan diferentes tipos de acciones de
 * automatización  con sus etiquetas y tipos de entrada.
 * @param translate - Es una función que toma una cadena como entrada y devuelve una versión traducida de
 * esa cadena basada en la configuración de idioma actual. Se utiliza para traducir los valores de la
 * Propiedad `value` en cada objeto de la matriz.
 */
export const AUTOMATION_ACTION_TYPES = translate => [
  {
    key: 'assign_agent',
    label: translate.t('AUTOMATION.ACTIONS.ASSIGN_AGENT'),
    inputType: 'search_select',
  },
  {
    key: 'assign_team',
    label: translate.t('AUTOMATION.ACTIONS.ASSIGN_TEAM'),
    inputType: 'search_select',
  },
  {
    key: 'add_label',
    label: translate.t('AUTOMATION.ACTIONS.ADD_LABEL'),
    inputType: 'multi_select',
  },
  {
    key: 'send_email_to_team',
    label: translate.t('AUTOMATION.ACTIONS.SEND_EMAIL_TO_TEAM'),
    inputType: 'team_message',
  },
  {
    key: 'send_email_transcript',
    label: translate.t('AUTOMATION.ACTIONS.SEND_EMAIL_TRANSCRIPT'),
    inputType: 'email',
  },
  {
    key: 'mute_conversation',
    label: translate.t('AUTOMATION.ACTIONS.MUTE_CONVERSATION'),
    inputType: null,
  },
  {
    key: 'snooze_conversation',
    label: translate.t('AUTOMATION.ACTIONS.SNOOZE_CONVERSATION'),
    inputType: null,
  },
  {
    key: 'resolve_conversation',
    label: translate.t('AUTOMATION.ACTIONS.RESOLVE_CONVERSATION'),
    inputType: null,
  },
  {
    key: 'send_webhook_event',
    label: translate.t('AUTOMATION.ACTIONS.SEND_WEBHOOK_EVENT'),
    inputType: 'url',
  },
  {
    key: 'send_attachment',
    label: translate.t('AUTOMATION.ACTIONS.SEND_ATTACHMENT'),
    inputType: 'attachment',
  },
  {
    key: 'send_message',
    label: translate.t('AUTOMATION.ACTIONS.SEND_MESSAGE'),
    inputType: 'textarea',
  },
];
