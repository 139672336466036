<template>
  <div class="image message-text__wrap content-img-loader">
    <img
      :id="'img-content-' + id"
      :src="imageUrl"
      :alt="'img' + id"
      class="image-loader"
      @click="onClick"
      @error="onImgError()"
      @load="onLoaded"
    />
    <woot-modal :full-width="true" :show.sync="show" :on-close="onClose">
      <img :src="url" class="modal-image" />
    </woot-modal>
  </div>
</template>

<script>
import { getBytesImg } from '../../../../helper/commons';
export default {
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      imageUrl: 'https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif',
    };
  },
  async mounted() {
    await this.onGenerateImage();
  },
  methods: {
    /**
     * Método emitir evento al componente padre avisando que la imagen ha sido cargada
     */
    onLoaded() {
      this.$emit('load');
    },
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
    onImgError() {
      this.$emit('error');
    },
    async onGenerateImage() {
      try {
        const image = document.getElementById(`img-content-${this.id}`);
        this.imageUrl = await getBytesImg(this.url, this.id);
        image.className = '';
        const parent = image.parentElement;
        parent.className = 'image message-text__wrap';
      } catch (error) {
        console.error('[ERROR]', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-img-loader {
  display: flex;
  width: 32rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
}
.image-loader {
  width: 50px;
  height: 50px;
}
</style>
