import MessageApi from '../../../../api/inbox/message';
import mutationTypes from '../../../mutation-types';

export const actions = {
  /**
   * Intenta obtener las transcripciones de un mensaje y almacenarlas en el store de Vuex.
   * Si tiene éxito, las transcripciones se almacenan; de lo contrario, se maneja el error.
   * @async
   * @param {object} commit - La función de commit de Vuex para realizar mutaciones.
   * @param {string} conversationId - El ID de la conversación.
   * @param {string} messageId - El ID del mensaje.
   */
  async transcriptMessage({ commit }, { conversationId, messageId }) {
    try {
      const response = await MessageApi.getTranscriptions(
        conversationId,
        messageId
      );
      const transcriptions = response.data;
      commit(mutationTypes.SET_TRANSCRIPTIONS, transcriptions);
    } catch (error) {
      // Llama a la función clearTranscriptions
      actions.clearTranscriptions({ commit });
      console.error('Error al obtener transcripciones:', error);
    }
  },

  // Define la función clearTranscriptions como parte de actions
  clearTranscriptions({ commit }) {
    commit(mutationTypes.SET_TRANSCRIPTIONS, []);
  },
};

export default actions;
