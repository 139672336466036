<template>
  <div v-if="contentAttributes && contentAttributes.items">
    <button class="option-button" :disabled="true">
      <svg
        class="button-icon"
        width="13"
        height="9"
        viewBox="0 0 13 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 0.5C0.5 0.223858 0.723858 0 1 0H12C12.2761 0 12.5 0.223858 12.5 0.5C12.5 0.776142 12.2761 1 12 1H1C0.723858 1 0.5 0.776142 0.5 0.5ZM0.5 4.5C0.5 4.22386 0.723858 4 1 4H12C12.2761 4 12.5 4.22386 12.5 4.5C12.5 4.77614 12.2761 5 12 5H1C0.723858 5 0.5 4.77614 0.5 4.5ZM0.5 8.5C0.5 8.22386 0.723858 8 1 8H12C12.2761 8 12.5 8.22386 12.5 8.5C12.5 8.77614 12.2761 9 12 9H1C0.723858 9 0.5 8.77614 0.5 8.5Z"
        />
      </svg>

      <span class="option-text">{{ $t('CHOOSE_OPTION') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    contentAttributes: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.option-button {
  margin-top: var(--space-smaller);
  background-color: white;
  color: var(--conversa2-blue-700-color);
  border: 1px solid var(--conversa2-blue-300-color);
  padding: var(--space-small) var(--space-one);
  border-radius: var(--border-radius-normal-small);
  transition: background-color 0.3s ease;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.option-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 16px;
  text-align: center;
  font-size: var(--font-size-mini);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.button-icon {
  fill: var(--conversa2-blue-700-color);
  margin-right: 4px;
}
</style>
