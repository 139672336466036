<template>
  <woot-modal :full-width="true" :show.sync="show" :on-close="onClose">
    <div v-if="typeFile === 'svg'" class="modal-content-svg">
      <div class="svg-content">
        <img
          :id="'svg-content-modal-img-' + id"
          :src="svgUrl"
          :alt="'img-modal' + id"
          class="content-svg-modal"
        />
      </div>
    </div>
    <div v-else-if="typeFile === 'gif'">
      <img
        :id="'gif-content-modal-img-' + id"
        src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"
        :alt="id"
      />
    </div>
    <div v-else class="modal-content-tiff">
      <img
        :id="'tiff-content-canvas-modal' + id"
        src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"
        :alt="'img-modal' + id"
        class="content-tiff-modal"
      />
    </div>
  </woot-modal>
</template>

<script>
import {
  getBytesSVG
} from '../../../../../helper/commons';
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    typeFile: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      svgUrl: '',
    };
  },
  mounted() {
    if (this.typeFile === 'svg') {
      this.onGenerateSVG('svg-content-modal-img-' + this.id);
    }
  },
  methods: {
    async onGenerateSVG(imageContent) {
      try {
        const image = document.getElementById(imageContent);
        const bytes = await getBytesSVG(this.url);
        const svgData = new Blob([bytes], { type: 'image/svg+xml' });
        const svgUrl = URL.createObjectURL(svgData);
        this.svgUrl = svgUrl;
        image.setAttribute('src', svgUrl);
        image.removeAttribute('class');
        const parent = image.parentElement;
        parent.setAttribute('class', 'image message-text__wrap');
      } catch (error) {
        console.error('[ERROR]', error);
      }
    },
  },
};
</script>
