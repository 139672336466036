<template lang="">
  <div class="file message-text__wrap">
    <div>
      <svg
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 24H1.5C0.671573 24 0 23.3284 0 22.5V1.5C0 0.671573 0.671573 0 1.5 0H12L18 6.75V22.5C18 23.3284 17.3284 24 16.5 24Z"
          fill="#1B67AC"
        />
        <path
          d="M12 5.25V0L18 6.75H13.5C12.6716 6.75 12 6.07843 12 5.25Z"
          fill="#6BB8FD"
        />
        <rect
          width="12"
          height="1.5"
          transform="translate(3 9.75)"
          fill="white"
        />
        <rect
          width="12"
          height="1.5"
          transform="translate(3 12.75)"
          fill="white"
        />
        <rect
          width="8.25"
          height="1.5"
          transform="translate(3 15.75)"
          fill="white"
        />
      </svg>
    </div>
    <div class="meta">
      <h5 class="text-block-title">
        {{ decodeURI(fileName) }}
      </h5>
      <a
        class="download clear link button small"
        rel="noreferrer noopener nofollow"
        target="_blank"
        :href="url"
      >
        <div class="wrapper__download">
          <fluent-icon
            v-tooltip.right="this.$t('CONVERSATION.DOWNLOAD')"
            icon="arrow-download"
            size="16"
          />
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    fileName() {
      if (this.url) {
        const filename = this.url.substring(this.url.lastIndexOf('/') + 1);
        return filename || this.$t('CONVERSATION.UNKNOWN_FILE_TYPE');
      }
      return this.$t('CONVERSATION.UNKNOWN_FILE_TYPE');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $space-smaller 0;
  cursor: pointer;

  .text-block-title {
    color: var(--conversa2-neutral-800-color);
    font-family: 'Source Sans Pro';
    font-size: var(--space-slab-plus);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: var(--space-two);
    white-space: nowrap;
    flex-grow: 1; /* Permite que el h5 crezca para llenar el espacio disponible */
    flex-shrink: 1; /* Permite que el h5 se encoja si es necesario */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .button {
    padding: 0;
    margin: 0;
    color: $color-primary-light;
  }

  .meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: var(--space-one);
    width: 27rem;
    min-width: 0;
  }

  .time {
    min-width: $space-larger;
  }
}
</style>
