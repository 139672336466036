/**
 * La función devuelve una matriz de objetos que contienen opciones de atributos para conversación y contacto.
 * @param translate - `translate` es una función que toma una cadena como entrada y devuelve
  * una versión traducida de esa cadena. Es probable que la traducción se base en la configuración de idioma del aplicación o sitio web donde se utiliza este código.
 */
export const ATTRIBUTE_MODELS = (translate) => ([
  {
    id: 0,
    option: translate.t('ATTRIBUTES_MGMT.SELECT-APPLY-TO.CONVERSATION'),
  },
  {
    id: 1,
    option: translate.t('ATTRIBUTES_MGMT.SELECT-APPLY-TO.CONTACT'),
  },
]);

/**
 * La función devuelve una matriz de objetos que representan tipos de atributos con sus correspondientes
 * traducciones
 * @param translate - Es una función que toma una cadena como entrada y devuelve la versión traducida.
 * de esa cadena en función de la configuración de idioma actual.
 */
export const ATTRIBUTE_TYPES = (translate) => ([
  {
    id: 0,
    option: translate.t('ATTRIBUTES_MGMT.SELECT-TYPE.TEXT'),
  },
  {
    id: 1,
    option: translate.t('ATTRIBUTES_MGMT.SELECT-TYPE.NUMBER'),
  },
  {
    id: 4,
    option: translate.t('ATTRIBUTES_MGMT.SELECT-TYPE.LINK'),
  },
  {
    id: 5,
    option: translate.t('ATTRIBUTES_MGMT.SELECT-TYPE.DATE'),
  },
  {
    id: 6,
    option: translate.t('ATTRIBUTES_MGMT.SELECT-TYPE.LIST'),
  },
  {
    id: 7,
    option: translate.t('ATTRIBUTES_MGMT.SELECT-TYPE.CHECKBOX'),
  },
]);
