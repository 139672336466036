<template>
  <div class="date-picker">
    <date-picker
      :range="true"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      :style="cssVars"
      :lang="lang"
      @change="handleChange"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import langEs from 'vue2-datepicker/locale/es/es';
import langEn from 'vue2-datepicker/locale/es/en';
export default {
  components: { DatePicker },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    topPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    cssVars() {
      return {
        '--topPosition': this.topPosition + 'px',
      };
    },
    lang() {
      switch (this.$root.$i18n.locale) {
        case 'es': return langEs;
        default: return langEn;
      }
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style scoped>
.date-picker .mx-datepicker-range {
  top: var(--topPosition);
}
</style>
