<template>
  <div class="column content-box">
    <div class="column page-top-bar">
      <h2 class="page-sub-title">
        {{ $t('CONVERSATION.TRANSCRIPTION.TITLE') }}
      </h2>
      <div class="top-bar-details">
        <table class="detail-table">
          <thead>
            <tr>
              <th>{{ $t('CONVERSATION.TRANSCRIPTION.CONTACT') }}</th>
              <th>{{ $t('CONVERSATION.TRANSCRIPTION.AGENT') }}</th>
              <th>{{ $t('CONVERSATION.TRANSCRIPTION.DATE') }}</th>
              <th>{{ $t('CONVERSATION.TRANSCRIPTION.TIME') }}</th>
              <th>{{ $t('CONVERSATION.TRANSCRIPTION.DURATION') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ getContactName() }}</td>
              <td>{{ getCustomSenderName() }}</td>
              <td>{{ getCallDate() }}</td>
              <td>{{ getCallTime() }}</td>
              <td>{{ getDuration() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import 'moment/locale/es';

export default {
  props: {
    /**
     * El ID del mensaje.
     * @type {string}
     * @default ''
     */
    messageId: {
      type: String,
      default: '',
    },
    /**
     * Atributos de contenido del mensaje.
     * @type {Object}
     * @default {}
     */
    contentAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      /**
       * Obtiene el chat actualmente seleccionado.
       * @function currentChat
       * @memberof module:ConversationDetails
       * @type {Function}
       */
      currentChat: 'getSelectedChat',
    }),
  },
  methods: {
    /**
     * Obtiene el nombre del remitente personalizado.
     * @function getCustomSenderName
     * @memberof module:ConversationDetails
     * @returns {string} El nombre del remitente personalizado o '-' si no está presente.
     */
    getCustomSenderName() {
      return (
        (this.contentAttributes.custom_sender &&
          this.contentAttributes.custom_sender.name) ||
        '-'
      );
    },
    /**
     * Obtiene la fecha de la llamada formateada.
     * @function getCallDate
     * @memberof module:ConversationDetails
     * @returns {string} La fecha formateada o '-' si no está presente o no es válida.
     */
    getCallDate() {
      const callDateTime = this.contentAttributes.transcriptDetail
        ? moment(this.contentAttributes.transcriptDetail.callDateTime)
        : null;

      return callDateTime && callDateTime.isValid()
        ? callDateTime.format('DD/MMM/YYYY', { locale: 'es' }).replace('.', '')
        : '-';
    },
    /**
     * Obtiene la hora de la llamada formateada.
     * @function getCallTime
     * @memberof module:ConversationDetails
     * @returns {string} La hora formateada o '-' si no está presente o no es válida.
     */
    getCallTime() {
      const callDateTime = this.contentAttributes.transcriptDetail
        ? moment(this.contentAttributes.transcriptDetail.callDateTime)
        : null;

      return callDateTime && callDateTime.isValid()
        ? callDateTime.format('hh:mm A')
        : '-';
    },
    /**
     * Obtiene la duración de la llamada formateada en horas, minutos y segundos.
     * @function getDuration
     * @memberof module:ConversationDetails
     * @returns {string} La duración formateada en formato 'hh:mm:ss' o '-' si no está presente o no es válida.
     */
    getDuration() {
      const durationInSeconds = this.contentAttributes.transcriptDetail
        ? this.contentAttributes.transcriptDetail.duration
        : null;

      if (Number.isInteger(durationInSeconds) && durationInSeconds >= 0) {
        const duration = moment.duration(durationInSeconds, 'seconds');
        const hours = duration
          .hours()
          .toString()
          .padStart(2, '0');
        const minutes = duration
          .minutes()
          .toString()
          .padStart(2, '0');
        const seconds = duration
          .seconds()
          .toString()
          .padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      }

      return '-';
    },
    /**
     * Obtiene el nombre de contacto.
     * @function getContactName
     * @memberof module:ConversationDetails
     * @returns {string} El nombre de contacto o '-' si no está presente.
     */
    getContactName() {
      return (
        (this.currentChat.meta.sender.name &&
          this.currentChat.meta.sender.name) ||
        '-'
      );
    },
  },
};
</script>

<style scoped>
#modal-transcript .page-sub-title {
  color: var(--conversa2-neutral-800-color);
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 14px;
}

#modal-transcript .detail-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 0px;
}

#modal-transcript .detail-table th,
#modal-transcript .detail-table td {
  border-bottom: 1px solid transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px 0 0;
}

#modal-transcript .detail-table th {
  text-transform: none;
  color: #54565b;
}

#modal-transcript .detail-table td {
  color: var(--conversa2-neutral-800-color);
}
</style>
