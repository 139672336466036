<template>
  <base-icon
    :size="size"
    :icon="icon"
    :type="type"
    :icons="icons"
    :viewbox="viewbox"
    :width="width"
    :height="height"
  />
</template>
<script>
import BaseIcon from './Icon';
import icons from './icons.json';

export default {
  name: 'FluentIcon',
  components: {
    BaseIcon,
  },
  props: {
    viewbox: {
      type: String,
      default: '0 0 24 24',
    },
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '20',
    },
    type: {
      type: String,
      default: 'outline',
    },
    width: {
      type: [String],
      default: '',
    },
    height: {
      type: [String],
      default: '',
    },
  },
  data() {
    return { icons };
  },
};
</script>
